import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { ListJsModel } from "src/app/pages/tables/listjs/listjs.model";
import { Observable } from "rxjs";
import { ColDef } from "ag-grid-community";
import { Columns } from "src/app/public/models/table.models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrdersService } from "src/app/pages/tables/listjs/listjs.service";
import Swal from "sweetalert2";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-table-list-land",
  templateUrl: "./table-reastate.component.html",
  styleUrls: ["./table-reastate.component.scss"],
  providers: [OrdersService, DecimalPipe],
})
export class TableReastateComponent {
  submitted = false;
  ListJsData!: ListJsModel[];
  checkedList: any;
  masterSelected!: boolean;
  rotateClass: string = "";

  total$: Observable<number>;

  // Input: list data table pass from parent component
  @Input() data: any[] = [];
  @Input() page: number = 0; //Page hiện tại
  @Input() total: number = 0; // Tổng số bản ghi
  @Input() pageSize: number = 0; // Số lượng bản ghi ở page hiện tại
  @Input() limit: number = 0; // limit của mỗi page
  @Input() columns: ColDef[] = [];
  @Input() subColumnsDefines: Columns[] = [];
  @Input() title: any;
  @Input() logError: any; // log lỗi
  // Output
  @Output() pageChange = new EventEmitter();

  // Content child
  @ContentChild("headers")
  headers: TemplateRef<any> | null;

  @ContentChild("rows")
  rows: TemplateRef<any> | null;

  @ContentChild("rowExpand")
  rowExpand: TemplateRef<any> | null;

  @ContentChild("noData")
  noData: TemplateRef<any> | null;

  constructor(private modalService: NgbModal, public service: OrdersService) {
    this.total$ = service.total$;
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(event: any, content: any): void {
    //  Stop mở expand danh sách customer
    event.stopPropagation();
    this.submitted = false;
    this.modalService.open(content, { size: "md", centered: true });
  }

  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    for (var i = 0; i < this.ListJsData.length; i++) {
      this.ListJsData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  // Get List of Checked Items
  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.ListJsData.length; i++) {
      if (this.ListJsData[i].isSelected)
        this.checkedList.push(this.ListJsData[i]);
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }

  expandRow(data: any): void {
    data.isSelected = !data.isSelected;
    this.rotateClass = data.isSelected
      ? "animation-rotate"
      : "animation-rotate-back";
  }

  /**
   * Confirmation mail model
   */
  confirm(): void {
    Swal.fire({
      title: "Are you Sure ?",
      text: "Are you Sure You want to Remove this Record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "ListJs has been deleted.", "success");
      }
    });
  }
}
