<div class="modal-content">
  <div class="modal-header pb-3">
    <h5 class="modal-title">Bộ lọc nâng cao</h5>
    <button
      type="button"
      class="close"
      (click)="onCloseModal()"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <hr class="m-0" />

  <div class="modal-body customize-modal-content">
    <div>
      <div
        class="text-dark cursor-pointer"
        (click)="collapse.toggle()"
        [attr.aria-expanded]="isCollapsed"
      >
        <span class="badge badge-soft-primary fs-12"
          ><i
            [ngClass]="!isCollapsed ? 'bx bx-caret-down' : 'bx bx-caret-right'"
            class="pe-1"
          ></i
          >Thông tin chi tiết BĐS</span
        >
        <div class="border border-dashed"></div>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <app-filter-info-real-estate
          [formSearchAndFilter]="searchLandParams"
          [currentUrl]="currentUrl"
          [unitForm]="unitForm"
          [unitPrice]="unitPrice"
          [rangeDateDefault]="rangeDateDefault"
        ></app-filter-info-real-estate>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onResetField()">
      <i class="mdi mdi-restore"></i> Đặt lại
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSearchEventEmit(searchLandParams)"
    >
      Tìm kiếm
    </button>
  </div>
</div>
