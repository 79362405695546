<div [formGroup]="formRealEstate">
  <div formArrayName="customers" class="row">
    <div class="hl-mb-n2">
      <span class="badge badge-soft-primary fs-12"
        ><i class="mdi mdi-circle-medium"></i>Thông tin chủ sở hữu</span
      >
    </div>
    <div class="border border-dashed"></div>

    <div class="row py-2">
      <!-- Danh sách tên người chủ sở hữu -->
      <ng-container *ngIf="customers.controls.length">
        <ng-container *ngFor="let customer of customers.controls; index as i">
          <ng-container [formGroupName]="i">
            <div class="row">
              <div class="col-md-4">
                <label class="form-label">Họ & Tên</label>
                <input
                  type="name"
                  formControlName="fullName"
                  class="form-control"
                  placeholder=""
                />
              </div>
              <ng-container formArrayName="address">
                <div class="col-md-5">
                  <label class="form-label">Số điện thoại</label>
                  <ng-container
                    *ngFor="let _ of address(customer); index as j"
                    [formGroupName]="j"
                  >
                    <li class="row list-type-style-none pb-1">
                      <div class="row">
                        <div class="input-group">
                          <input
                            invisiblePhoneNumber
                            type="text"
                            formControlName="phone"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                          <button
                            *ngIf="
                              j === address(customer).length - 1 &&
                              PHONE_NUMBER_DEFAULT !== address(customer).length
                            "
                            [disabled]="
                              PHONE_NUMBER_DEFAULT === address(customer).length
                                ? true
                                : null
                            "
                            class="btn btn-outline-primary"
                            type="button"
                            ngbTooltip="Thêm mới SĐT"
                            (click)="addFieldPhone(i)"
                          >
                            <i class="mdi mdi-plus"></i>
                          </button>
                          <button
                            *ngIf="j !== address(customer).length - 1"
                            class="btn btn-outline-danger"
                            type="button"
                            ngbTooltip="Xoá bớt SĐT"
                            (click)="removeFieldPhone(i, j)"
                          >
                            <i class="ri-subtract-line"></i>
                          </button>
                          <button
                            *ngIf="
                              j === address(customer).length - 1 &&
                              address(customer).length > 1
                            "
                            class="btn btn-outline-danger"
                            type="button"
                            ngbTooltip="Xoá bớt SĐT"
                            (click)="removeFieldPhone(i, j)"
                          >
                            <i class="ri-subtract-line"></i>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </div>
              </ng-container>
              <div class="col-md-2 align-items-end d-flex pb-1">
                <button
                  (click)="removeOwner(i)"
                  class="btn btn-outline-danger"
                  type="button"
                  ngbTooltip="Xoá chủ sở hữu BĐS"
                >
                  <i class="bx bx bxs-user-minus fs-20 align-middle me-2"></i>
                  Xoá
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Show when have no content -->
      <ng-container *ngIf="!customers.controls.length">
        <div class="card-body text-center text-primary">
          <div>Không có thông tin chủ sở hữu.</div>
          <i class="fs-24 mdi mdi-database-off-outline"></i>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-md-4">
        <button
          *ngIf="customers.controls.length < PHONE_NUMBER_DEFAULT"
          (click)="addOwner()"
          class="btn btn-outline-primary my-2"
          type="button"
          ngbTooltip="Thêm chủ sở hữu BĐS"
        >
          <i class="bx bxs-user-plus align-middle me-2"></i> Thêm chủ sở hữu BĐS
        </button>
      </div>
    </div>
  </div>
</div>
