import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { PRICE_OPTIONS } from "src/app/public/constants/common.constant";
import { compareRange } from "src/app/public/utils/compareNumber";

@Component({
  selector: "app-filter-range",
  templateUrl: "./filter-range.component.html",
  styleUrls: ["./filter-range.component.scss"],
})
export class FilterRangeComponent implements OnInit {
  public optionsPrice = PRICE_OPTIONS;
  @Input() title: string;
  @Input() unit: string;
  @Input() fromControl: UntypedFormControl;
  @Input() toControl: UntypedFormControl;
  @Input() disabled: boolean;
  @Input() rangePrice: boolean = false;
  @Input() unitForm = new UntypedFormControl();
  @Input() dropdownRelative: boolean = false;

  @ViewChild("rangeARef") rangeARef: ElementRef;

  public formRange: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.formRange = this.fb.group(
      {
        startTo: [""],
        fromTo: [""],
      },
      { validator: compareRange("startTo", "fromTo") }
    );
  }

  ngOnInit(): void {
    this.formRange.valueChanges.subscribe((data) => {
      if (!this.getControl("fromTo").hasError("invalidNumber")) {
        this.fromControl.setValue(data.startTo);
        this.toControl.setValue(data.fromTo);
      }
    });
  }

  public getControl(name: string) {
    return this.formRange.get(name) as UntypedFormControl;
  }

  public handleReset() {
    this.formRange.reset();
    this.fromControl.reset();
    this.toControl.reset();

    this.rangeARef.nativeElement.focus();
  }
}
