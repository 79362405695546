<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Thông tin khác</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2 row">
    <div class="col-md-3 mt-2">
      <label class="form-label">Loại đường</label>
      <ng-select
        [readonly]="!isEdit"
        [clearable]="false"
        [items]="streetType$ | async"
        bindValue="cKey"
        bindLabel="cValue"
        formControlName="streetType"
        placeholder="Chọn loại đường..."
      ></ng-select>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Năm xây dựng</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          class="form-control"
          type="text"
          formControlName="constructYear"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Hồ sơ pháp lý</label>
      <ng-select
        [readonly]="!isEdit"
        [items]="jurdical$ | async"
        [clearable]="false"
        bindValue="cKey"
        bindLabel="cValue"
        formControlName="businessStatus"
        placeholder="Chọn hs pháp lý..."
      ></ng-select>
    </div>

    <!-- ĐƠN VỊ ĐANG THUÊ-->
    <div class="col-md-3 mt-2">
      <label class="form-label">Đơn vị đang thuê</label>
      <input
        class="form-control"
        [attr.disabled]="true"
        [value]="formRealEstate.get('tenant').value?.name"
      />
    </div>

    <div class="col-md-9 mt-2">
      <label class="form-label">Ghi chú</label>
      <ckeditor
        formControlName="note"
        [disabled]="!isEdit"
        [editor]="Editor"
      ></ckeditor>
    </div>

    <!-- Thời hạn hợp đồng-->
    <div class="col-md-3 mt-2">
      <label class="form-label">Thời hạn hợp đồng</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          class="form-control"
          type="number"
        />
      </div>
    </div>
  </div>
</div>
