import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  PAGE_DEFAULT,
  SIZE_PAGE_DEFAULT,
} from "src/app/public/constants/common.constant";
import {
  REAL_ESTATE_URL,
  STATUS_IN_PROGRESS,
  STATUS_QUICK_UPLOAD,
  STATUS_WAITING_APPROVE,
} from "src/app/public/constants/real-estate.constant";
import { minLengthValidator } from "src/app/public/utils/app-validators";
import { FilterInfoRealEstateComponent } from "./filter-info-real-estate/filter-info-real-estate.component";

@Component({
  selector: "app-full-search",
  templateUrl: "./full-search.component.html",
  styleUrls: ["./full-search.component.scss"],
})
export class FullSearchComponent implements OnInit {
  @Output() onSearchEvent = new EventEmitter();
  @ViewChild(FilterInfoRealEstateComponent)
  infoComponent: FilterInfoRealEstateComponent;

  public STATUS_QUICK_UPLOAD = STATUS_QUICK_UPLOAD;
  public STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;
  public STATUS_WAITING_APPROVED = STATUS_WAITING_APPROVE;
  public isCollapsed = false;
  public searchLandParams: UntypedFormGroup;
  public currentUrl: string;
  public unitForm = new FormControl("Triệu");
  public unitPrice = this.unitForm.value;
  public rangeDateDefault: { createdFrom: string; createdTo: string };

  constructor(
    private modalService: NgbActiveModal,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.patchParams();
    this.observeSelectPriceChange();
    this.notAllowPatchEmptyValue();
  }

  private getControl(name: string) {
    return this.searchLandParams.controls[name] as FormControl;
  }

  private notAllowPatchEmptyValue() {
    this.searchLandParams.valueChanges.subscribe(({ status, createdBy }) => {
      if (!status?.length) {
        this.handlePatchStatus();
      }
      if (!createdBy?.length) {
        this.getControl("createdBy").setValue(null, { emitEvent: false });
      }
    });
  }

  private initForm() {
    this.searchLandParams = this.fb.group({
      types: [""],
      purpose: [""],
      status: [""],
      fromPrice: [""],
      toPrice: [""],
      createdFrom: [""],
      createdTo: [""],
      createdBy: [
        null,
        Validators.compose([Validators.maxLength(10), minLengthValidator]),
      ],
    });
  }

  private observeSelectPriceChange() {
    this.unitForm.valueChanges.subscribe((newValue) => {
      this.unitPrice = newValue;
    });
  }

  private patchParams() {
    this.route.queryParams.subscribe((params: any) => {
      const {
        status,
        types,
        purpose,
        fromPrice,
        toPrice,
        createdFrom,
        createdTo,
        ..._params
      } = params;

      this.searchLandParams.patchValue({
        ..._params,
        status: this.convertStringToArray(status),
        types: this.convertStringToArray(types),
        purpose: this.convertStringToArray(purpose),
        fromPrice: this.convertPriceUnitReverse(fromPrice),
        toPrice: this.convertPriceUnitReverse(toPrice),
      });

      this.rangeDateDefault = {
        createdFrom,
        createdTo,
      };
    });
  }

  private convertStringToArray(value: string) {
    return value?.split(";").filter((item: string) => item.trim() !== "");
  }

  public onCloseModal() {
    this.modalService.close();
  }

  public onSearchEventEmit(searchForm: FormGroup) {
    if (searchForm.valid) {
      const { fromPrice, toPrice, ...paramValues } = searchForm.value;

      const searchLandParams = {
        ...paramValues,
        fromPrice: fromPrice ? this.convertPriceUnit(fromPrice) : null,
        toPrice: toPrice ? this.convertPriceUnit(toPrice) : null,
      };

      this.onSearchEvent.emit(searchLandParams);

      this.onCloseModal();
    }
  }

  public onResetField() {
    this.searchLandParams.reset();
    this.infoComponent.resetForm();
    this.handlePatchStatus();
  }

  private handlePatchStatus() {
    switch (this.currentUrl) {
      case REAL_ESTATE_URL.QUICK_UPLOAD:
        this.patchDefaultStatus(this.STATUS_QUICK_UPLOAD);
        break;
      case REAL_ESTATE_URL.IN_PROGRESS:
        this.patchDefaultStatus(this.STATUS_IN_PROGRESS);
        break;
      case REAL_ESTATE_URL.WAITING_APPROVE:
        this.patchDefaultStatus(this.STATUS_WAITING_APPROVED);
        break;
      default:
        break;
    }
  }

  private patchDefaultStatus(status: string[]) {
    this.searchLandParams.patchValue({
      status: status,
      page: PAGE_DEFAULT,
      limit: SIZE_PAGE_DEFAULT,
    });
  }

  private convertPriceUnit(price: any): number {
    switch (this.unitPrice) {
      case "Triệu":
        return price * 1000000;
      case "Tỷ":
        return price * 1000000000;
      default:
        return price;
    }
  }

  private convertPriceUnitReverse(price: any): number {
    switch (true) {
      case price <= 999999999: {
        this.unitForm.setValue("Triệu");
        return price / 1000000;
      }
      case price >= 999999999: {
        this.unitForm.setValue("Tỷ");
        return price / 1000000000;
      }
      default:
        return price;
    }
  }
}
