import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { APP_ACCOUNT_TYPE } from "src/app/public/constants/user.constant";
import { UserService } from "src/app/public/services/user/user.service";
import { USER_INFO } from "../constants/common.constant";
import { GroupModel } from "../models/group.model";
import { StorageService } from "../services/storage/storage.service";
import { maskPhoneNumber } from "../utils/hidden-phone-number";

@Pipe({
  name: "invisiblePhoneNumber",
})
export class InvisiblePhoneNumberPipe implements PipeTransform {
  constructor(
    private userService: UserService,
    private storageService: StorageService
  ) {}

  transform(value: any, ...args: any[]): Observable<any> {
    return this.userService.user$.pipe(
      map((data: { type: string }) => {
        const groups = this.getUserGroups();
        const notHiddenPhone = groups.some(
          (group: GroupModel) => group.id === 2 || group.id === 4
        );

        if (data.type === APP_ACCOUNT_TYPE.SUPER_ADMIN || notHiddenPhone)
          return value;
        return maskPhoneNumber(value);
      })
    );
  }

  private getUserGroups(): GroupModel[] {
    const userGroups = this.storageService.get(USER_INFO).groups;

    return userGroups ? userGroups : [];
  }
}
