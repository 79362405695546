<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Kích thước chi tiết</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="row py-2">
    <div class="col-md-3 mt-2">
      <label class="form-label">Diện tích đất</label>
      <input
        [attr.disabled]="isEdit ? null : true"
        formControlName="landArea"
        class="form-control"
        placeholder="(m2)"
      />
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Diện tích XD</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="acreage"
          class="form-control"
          placeholder="(m2)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Mặt tiền</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="frontWidth"
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label"
        >Mặt tiền(<abbr title="Thông Thuỷ">TT</abbr>)</label
      >
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="clearanceFw"
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Mặt sau</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="backWidth"
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label"
        >Mặt sau(<abbr title="Thông Thuỷ">TT</abbr>)</label
      >
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="clearanceBw"
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Chiều dài</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="length"
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label"
        >Chiều dài(<abbr title="Thông Thuỷ">TT</abbr>)</label
      >
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="clearanceLength"
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>
  </div>
</div>
