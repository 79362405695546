import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { RealEstateMarkService } from "src/app/public/services/real-estate/real-estate-mark.service";
import { RealEstateModel } from "src/app/public/models/real-estate.model";

@Directive({
  selector: "[btnCheckRealEstateMark]",
  exportAs: "btnCheckRealEstateMark",
})
export class BtnCheckRealEstateMarkDirective {
  private listPoints: number[] = [];

  constructor(
    private makerService: RealEstateMarkService,
    private el: ElementRef,
    private render: Renderer2
  ) {
    makerService.realEstatesMark$.subscribe(
      (realEstates: RealEstateModel[]) => {
        this.listPoints = realEstates?.map((r: { id: any }) => r.id) || [];
      }
    );
  }

  @Input("btnCheckRealEstateMark") set btnCheckRealEstateMark(id: number) {
    const realEstateIsMark = this.listPoints.find((p) => p === id);
    const color = realEstateIsMark ? "#fff" : "#b3b3b3";
    const bgColor = realEstateIsMark ? "#0ab39c" : "#fff";

    this.render.setStyle(this.el?.nativeElement, "background", bgColor);
    this.render.setStyle(this.el?.nativeElement, "color", color);
    this.render.setStyle(this.el?.nativeElement, "border-color", color);
  }
}
