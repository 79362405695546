import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Directive({
    selector: "[appGetOwnerRealEstate]",
})
export class GetOwnerRealEstateDirective implements OnInit, OnChanges {
  @Input() username: string;
  @Input() users: any[];

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.execute();
  }

  ngOnInit(): void {
    this.execute();
  }

  execute(): void {
    (this.el.nativeElement as HTMLElement).innerHTML = this.getFullName();
  }

  getFullName(): string {
    if (this.users && this.users.length)
      return this.users?.find((u) => u.phone === this.username)?.fullName || "";
    return "";
  }
}
