<div class="card-body border border-dashed border-end-0 border-start-0">
  <form [formGroup]="searchCustomerParams">
    <div class="row g-3">
      <!--LOẠI TỔ CHỨC-->
      <div class="col-xxl-1 col-sm-12" *ngIf="getControl('type')">
        <div class="search-box">
          <ng-select
            [multiple]="true"
            class="col-md-5 p-0 mb-2 w-100"
            placeholder="Tổ chức"
            [items]="USER_TYPE"
            formControlName="type"
            bindValue="key"
          ></ng-select>
        </div>
      </div>

      <!--LOẠI TRẠNG THÁI NGƯỜI DÙNG-->
      <div class="col-xxl-1 col-sm-12" *ngIf="!getControl('status').disabled">
        <div class="search-box">
          <ng-select
            [multiple]="true"
            bindValue="key"
            formControlName="status"
            [searchable]="false"
            class="col-md-5 p-0 mb-2 w-100"
            placeholder="Trạng thái"
            [items]="USER_STATUS"
            bindLabel="label"
          ></ng-select>
        </div>
      </div>

      <!--HỌ VÀ TÊN-->
      <div class="col-xxl-2 col-sm-12" *ngIf="getControl('name')">
        <div class="search-box">
          <input
            type="text"
            class="form-control search"
            placeholder="Họ và tên"
            formControlName="name"
          />
          <i class="ri-user-3-line search-icon"></i>
        </div>
      </div>

      <!--TÊN CÔNG TY-->
      <div class="col-xxl-2 col-sm-12">
        <div class="search-box">
          <input
            type="text"
            class="form-control search"
            placeholder="Tên công ty"
          />
          <i class="ri-community-line search-icon"></i>
        </div>
      </div>

      <!--SỐ ĐIỆN THOẠI-->
      <div class="col-xxl-2 col-sm-12" *ngIf="getControl('phone')">
        <div class="search-box">
          <input
            type="text"
            class="form-control search"
            placeholder="Số điện thoại"
            formControlName="phone"
          />
          <i class="ri-phone-line search-icon"></i>
        </div>
      </div>

      <!--EMAIL-->
      <div class="col-xxl-2 col-sm-12" *ngIf="getControl('email')">
        <div class="search-box">
          <input
            type="text"
            class="form-control search"
            placeholder="Email"
            formControlName="email"
          />
          <i class="ri-mail-line search-icon"></i>
        </div>
      </div>

      <!--LỰA CHỌN NGÀY THÁNG-->
      <div
        class="col-xxl-2 col-sm-12"
        *ngIf="getControl('startDate') && getControl('endDate')"
      >
        <div class="form-control position-relative customize-range-date">
          <mat-date-range-input
            [formGroup]="formRange"
            [rangePicker]="picker"
            [max]="maxDate"
          >
            <input
              matStartDate
              formControlName="startTo"
              placeholder="Từ ngày"
              readonly
            />
            <input
              matEndDate
              formControlName="endTo"
              placeholder="Đến ngày"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <i
            class="ri-close-line delete-btn position-absolute fs-20"
            (click)="handleReset()"
            *ngIf="this.formatStartDate && this.formatEndDate"
          ></i>
        </div>
      </div>

      <!--ACTION TÌM KIẾM-->
      <div class="col-xxl-2 col-sm-4">
        <button
          (click)="onClickSubmitSearch()"
          type="submit"
          class="btn btn-primary"
        >
          <i class="ri-search-2-line me-1 align-bottom"></i>
          Tìm kiếm
        </button>
      </div>
    </div>
    <!--END ROW-->
  </form>
</div>
