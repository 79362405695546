<ng-container [formGroup]="formSearchAndFilter">
  <div class="row row-cols-5 pt-3">
    <div class="col mb-3">
      <label class="form-label">ID</label>
      <input
        type="text"
        placeholder="Nhập ID"
        class="form-control"
        formControlName="id"
      />
    </div>
    <div class="col mb-3">
      <label class="form-label">Trạng thái BĐS</label>
      <app-filter-status
        [status]="STATUS_APPROVED"
        [formChild]="getControl('status')"
      ></app-filter-status>
    </div>

    <div class="col mb-3 customize-field">
      <label class="form-label">Loại BĐS</label>
      <app-filter-type [formChild]="getControl('types')"></app-filter-type>
    </div>
    <div class="col mb-3">
      <label class="form-label">Nhu cầu</label>
      <app-filter-purpose
        [childControl]="getControl('purpose')"
      ></app-filter-purpose>
    </div>

    <div class="col mb-3">
      <label class="form-label">Nhóm khách hàng</label>
      <ng-select
        [items]="OWNER_GROUPS"
        bindLabel="label"
        bindValue="key"
        placeholder="Chọn nhóm khách hàng"
        formControlName="groupCustomer"
      ></ng-select>
    </div>
  </div>

  <div class="row row-cols-5">
    <div class="col mb-3">
      <label class="form-label">Tên khách hàng</label>
      <input
        type="text"
        placeholder="Nhập tên khách hàng"
        class="form-control"
        formControlName="customers"
      />
    </div>
  </div>
</ng-container>
