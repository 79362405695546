import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getThumbnail",
})
export class GetThumbnailImagePipe implements PipeTransform {
  SMALL_THUMBNAIL = "380x380";

  transform(url: string): string {
    if (url) {
      return url.replace("0x0", this.SMALL_THUMBNAIL);
    } else if (url === null) {
      return "./assets/images/products/no-img.webp";
    }
    return url;
  }
}
