import {
  Component,
  ContentChild,
  TemplateRef,
  Input,
} from "@angular/core";

@Component({
  selector: "app-table-suppliers",
  templateUrl: "./table-suppliers.component.html",
  styleUrls: ["./table-suppliers.component.scss"],
})
export class TableSuppliersComponent  {
  // Content-Child
  @ContentChild("headers") headers: TemplateRef<any>;
  @ContentChild("rows") rows: TemplateRef<any>;

  // Input data
  @Input() data: any[] = [];
}
