<div class="row pt-3">
  <div class="col-4 mb-3">
    <label class="form-label">Loại hình BĐS</label>
    <app-filter-type [formChild]="getControl('types')"></app-filter-type>
  </div>
  <div class="col-4 mb-3">
    <label class="form-label">Kiểu BĐS</label>
    <app-filter-purpose
      [childControl]="getControl('purpose')"
    ></app-filter-purpose>
  </div>
  <div class="col-4 mb-3" *ngIf="currentUrl !== REAL_ESTATE_URL.QUICK_UPLOAD">
    <label class="form-label">Trạng thái BĐS</label>
    <app-filter-status
      [currentUrl]="currentUrl"
      [status]="STATUS_APPROVED"
      [formChild]="getControl('status')"
    ></app-filter-status>
  </div>
  <div class="col-4 mb-3">
    <label class="form-label">Khoảng giá</label>
    <app-filter-range
      title="Khoảng giá"
      [rangePrice]="true"
      [unit]="unitPrice"
      [unitForm]="unitForm"
      [fromControl]="getControl('fromPrice')"
      [toControl]="getControl('toPrice')"
    ></app-filter-range>
  </div>
  <div class="col-4 mb-3">
    <label class="form-label">Ngày tạo BĐS</label>
    <app-filter-date-range
      [fromControl]="getControl('createdFrom')"
      [toControl]="getControl('createdTo')"
      [defaultValue]="rangeDateDefault"
      placeholder="Chọn ngày tạo BĐS"
      (formRangeEvent)="onFormRangeChange($event)"
    ></app-filter-date-range>
  </div>
  <div class="col-4 mb-3">
    <label class="form-label">Người tạo</label>
    <app-filter-creator
      [childControl]="getControl('createdBy')"
    ></app-filter-creator>
    <small
      *ngIf="getControl('createdBy').touched && getControl('createdBy').errors?.['minLength']"
      class="text-danger"
      >Bạn cần nhập 3 kí tự trở lên
    </small>
  </div>
</div>
