import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-filter-creator",
  templateUrl: "./filter-creator.component.html",
})
export class FilterCreatorComponent {
  @Input() childControl: UntypedFormControl = new UntypedFormControl(null);

  constructor() {}
}
