export const BC_CUSTOMER_ACTIVE = [
  { label: "CRM" },
  { label: "Danh sách khách hàng" },
  { label: "Đang hoạt động", active: true },
];

export const BC_CUSTOMER_WAITING_APPROVED = [
  { label: "CRM" },
  { label: "Danh sách khách hàng" },
  { label: "Chờ duyệt", active: true },
];

export const BC_CUSTOMER_REJECT = [
  { label: "CRM" },
  { label: "Danh sách khách hàng" },
  { label: "Từ chối", active: true },
];

export const BC_CUSTOMER_DETAIL = [
  { label: "Danh sách khách hàng" },
  { label: "Thông tin chi tiết khách hàng", active: true },
];

export const BC_TASK = [
  { label: "CRM" },
  { label: "Danh sách task", active: true },
];

export const BC_TASK_DETAIL = [
  { label: "Danh sách task" },
  { label: "Chi tiết task", active: true },
];

export const BC_SETTING_NOTIFY = [
  { label: "CRM" },
  {
    label: "Cài đặt",
  },
  {
    label: "Gửi thông báo",
    active: true,
  },
];

export const BC_EMAIL_VERIFICATION = [
  { label: "CRM" },
  {
    label: "Cài đặt",
  },
  {
    label: "Danh sách duyệt nhận email",
    active: true,
  },
];

export const BC_AGENT = [
  { label: "Danh sách cộng tác viên" },
  { label: "Đang hoạt động", active: true },
];

export const BC_AGENT_DETAIL = [
  { label: "Quản lý cộng tác viên" },
  { label: "Chi tiết cộng tác viên", active: true },
];

export const BC_EMPLOYEE_DETAIL = [
  { label: "Quản lý nhân viên" },
  { label: "Chi tiết nhân viên", active: true },
];

export const BC_REAL_ESTATE = [{ label: "Danh sách BĐS", active: true }];

export const BC_WAITING_APPROVED = [
  { label: "Danh sách BĐS" },
  { label: "Chờ duyệt", active: true },
];

export const BC_IN_PROGRESS = [
  { label: "Danh sách BĐS" },
  { label: "Đang xử lý", active: true },
];

export const BC_QUICK_UP_LOAD = [
  { label: "Danh sách BĐS" },
  { label: "Đăng nhanh", active: true },
];

export const BC_REAL_ESTATE_DETAIL = [
  { label: "Quản lý BĐS" },
  { label: "Chi tiết BĐS", active: true },
];

export const BC_REAL_ESTATE_CREATION = [
  { label: "Quản lý BĐS" },
  { label: "Tạo mới BĐS", active: true },
];

export const BC_PERMISSION_ACTION = [
  { label: "Quyền truy cập" },
  { label: "Action", active: true },
];

export const BC_PERMISSION_SERVICE = [
  { label: "Quyền truy cập" },
  { label: "Service", active: true },
];

export const BC_PERMISSION_RESOURCE = [
  { label: "Quyền truy cập" },
  { label: "Resource", active: true },
];

export const BC_PERMISSION_POLICY = [
  { label: "Quyền truy cập" },
  { label: "Policy", active: true },
];

export const BC_PERMISSION_ROLE = [
  { label: "Quyền truy cập" },
  { label: "Role", active: true },
];

export const BC_DIVISION = [
  { label: "Cơ cấu tổ chức" },
  { label: "Danh sách phòng ban", active: true },
];

export const BC_GROUP = [
  { label: "Cơ cấu tổ chức" },
  { label: "Danh sách nhóm", active: true },
];

export const BC_EMPLOYEE = [
  { label: "Cơ cấu tổ chức" },
  { label: "Danh sách nhân viên", active: true },
];

export const BC_GROUP_DETAIL = [
  { label: "Danh sách nhóm" },
  { label: "Chi tiết nhóm", active: true },
];

export const BC_GROUP_CREATION = [
  { label: "Danh sách nhóm" },
  { label: "Thêm nhóm", active: true },
];
