import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormSearchRealEstate } from "../models/form-search-realestate.model";
import {
  PAGE_DEFAULT,
  SIZE_PAGE_DEFAULT,
} from "./../constants/common.constant";
import { minLengthValidator } from "../utils/app-validators";

@Injectable({
  providedIn: "root",
})
export class FormSearchRealEstateHelper {
  readonly #form: FormGroup;

  constructor() {
    this.#form = FormSearchRealEstateHelper.initForm();
  }

  get form() {
    return this.#form as FormGroup;
  }

  private static initForm(data?: FormSearchRealEstate): FormGroup {
    return new FormGroup({
      cenLat: new FormControl(data?.cenLat || null),
      cenLng: new FormControl(data?.cenLng || null),
      zoom: new FormControl(data?.zoom || null),
      groupStatus: new FormControl(data?.groupStatus || null),
      types: new FormControl(data?.types || "", {
        nonNullable: true,
      }),
      search: new FormControl(data?.search || "", {
        nonNullable: true,
      }),
      purpose: new FormControl(data?.purpose || ""),
      fromPrice: new FormControl(data?.fromPrice || ""),
      toPrice: new FormControl(data?.toPrice || ""),
      area: new FormControl(data?.area || ""),
      frontWidth: new FormControl(data?.frontWidth || ""),
      frontWidthFrom: new FormControl(data?.frontWidthFrom || ""),
      frontWidthTo: new FormControl(data?.frontWidthTo || ""),
      acreageFrom: new FormControl(data?.acreageFrom || ""),
      acreageTo: new FormControl(data?.frontWidthTo || ""),
      bedRoom: new FormControl(data?.bedRoom || null),
      bathRoom: new FormControl(data?.bathRoom || null),
      page: new FormControl(PAGE_DEFAULT, { nonNullable: true }),
      limit: new FormControl(SIZE_PAGE_DEFAULT, { nonNullable: true }),
      status: new FormControl(data?.status || [""]),
      address: new FormControl(data?.address || ""),
      city: new FormControl(data?.city || null),
      district: new FormControl(data?.district || ""),
      street: new FormControl(data?.street || ""),
      town: new FormControl(data?.town || ""),
      orderColumns: new FormControl("updated"),
      createdFrom: new FormControl(data?.createdFrom || null),
      createdTo: new FormControl(data?.createdTo || null),
      createdBy: new FormControl(data?.createdBy || null, {
        validators: Validators.compose([minLengthValidator]),
      }),
      basement: new FormControl(data?.basement || null),
      corner: new FormControl(data?.corner || null),
      sorts: new FormControl(data?.sorts || null),
      hasLocation: new FormControl(data?.hasLocation || null),
      hasImage: new FormControl(data?.hasImage || null),
      hasVideo: new FormControl(data?.hasVideo || null),
      floorFrom: new FormControl(data?.floorFrom || null),
      floorTo: new FormControl(data?.floorTo || null),
      houseNbr: new FormControl(data?.houseNbr || null),
      acreageForRentFrom: new FormControl(data?.acreageForRentFrom || null),
      acreageForRentTo: new FormControl(data?.acreageForRentTo || null),
      acreageBuiltFrom: new FormControl(data?.acreageBuiltFrom || null),
      acreageBuiltTo: new FormControl(data?.acreageBuiltTo || null),
      contractTerm: new FormControl(data?.contractTerm || null),
      brokerageContract: new FormControl(data?.brokerageContract || null),
      origin: new FormControl(data?.origin || null),
      brokerageFee: new FormControl(data?.brokerageFee || null),
      groupCustomer: new FormControl(data?.groupCustomer || null),
      customers: new FormControl(data?.customers || null),
      broker: new FormControl(data?.broker || null),
      siteHandoverTimeFrom: new FormControl(data?.siteHandoverTimeFrom || null),
      siteHandoverTimeTo: new FormControl(data?.siteHandoverTimeTo || null),
      landAreaFrom: new FormControl(data?.landAreaFrom || null),
      landAreaTo: new FormControl(data?.landAreaTo || null),
      negotiate: new FormControl(data?.negotiate || false),
      currency: new FormControl(data?.currency || null),
      businessStatus: new FormControl(data?.businessStatus || null),
      id: new FormControl(data?.id || null),
      manager: new FormControl(data?.manager || null),
      openingTimeFrom: new FormControl(data?.openingTimeFrom || null),
      openingTimeTo: new FormControl(data?.openingTimeTo || null),
    });
  }
}
