import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import Vietnam from "flatpickr/dist/l10n/vn";

@Component({
  selector: "app-filter-date-range",
  templateUrl: "./filter-date-range.component.html",
})
export class FilterDateRangeComponent implements OnInit {
  @Input() fromControl: UntypedFormControl;
  @Input() toControl: UntypedFormControl;
  @Input() defaultValue: { createdFrom: string; createdTo: string };
  @Input() placeholder: string;
  @Output() formRangeEvent = new EventEmitter<UntypedFormGroup>();
  public today = new Date();
  public formRange: UntypedFormGroup;
  public language = Vietnam.vn;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.patchDefaultValue();
    this.observeFormChange();
    this.formRangeEventEmit();
  }

  private initForm() {
    this.formRange = this.fb.group({
      dateRange: [""],
    });
  }

  private patchDefaultValue() {
    if (this.defaultValue) {
      const { createdFrom, createdTo } = this.defaultValue;
      if (createdFrom && createdTo) {
        this.formRange.patchValue({
          dateRange: this.isoToString(this.defaultValue),
        });
        this.fromControl.setValue(createdFrom);
        this.toControl.setValue(createdTo);
      }
    }
  }

  private isoToString(range: { createdFrom: string; createdTo: string }) {
    const startDate = new Date(range?.createdFrom).toISOString().split("T")[0];
    const endDate = new Date(range?.createdTo).toISOString().split("T")[0];
    return `${startDate} đến ${endDate}`;
  }

  private observeFormChange() {
    this.formRange.valueChanges.subscribe(({ dateRange }) => {
      this.handleDateChange(dateRange);
    });
  }

  private handleDateChange(dateRange: string) {
    const [start, end] = dateRange?.split(" đến") ?? [];

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      startDate.setHours(startDate.getHours() + 7);
      endDate.setHours(endDate.getHours() + 30, 59, 59, 59);

      this.fromControl.setValue(startDate.toISOString(), { emitEvent: false });
      this.toControl.setValue(endDate.toISOString(), { emitEvent: false });
    } else {
      this.fromControl.setValue(null, { emitEvent: false });
      this.toControl.setValue(null, { emitEvent: false });
    }
  }

  private formRangeEventEmit() {
    this.formRangeEvent.emit(this.formRange);
  }
}
