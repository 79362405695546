import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Component({
  selector: "app-form-other-info",
  templateUrl: "./form-other-info.component.html",
  styleUrls: ["./form-other-info.component.scss"],
})
export class FormOtherInfoComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() isEdit: boolean;

  public Editor = ClassicEditor;
  streetType$: Observable<any>;
  jurdical$: Observable<any>;

  constructor(private categoryService: CategoryService) {
    this.streetType$ = categoryService.getAllCateStreet();
    this.jurdical$ = categoryService.getAllJuridical();
  }
}
