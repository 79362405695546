<div class="map-container">
  <div class="input-location">
    <input
      [readonly]="readonly"
      [formControl]="inputControl"
      class="form form-control"
      placeholder="Nhập toạ độ"
    />
  </div>
  <ng-container>
    <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [disableDefaultUI]="false"
      [zoomControl]="false"
      (dragend)="markerDragEnd($event)"
      [mapDraggable]="!readonly"
    >
      <agm-marker
        [latitude]="lat"
        [longitude]="lng"
        [label]="''"
        [markerDraggable]="!readonly"
        (dragEnd)="markerDragEnd($event)"
      >
        <agm-info-window>
          <strong>InfoWindow content</strong>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </ng-container>

  <div class="controls" *ngIf="linkStreetView" (click)="clickStreetView(1)">
    <img [src]="listPhotos?.[0]?.url || './assets/images/img.png'" alt="" />
    <div></div>
    <p>Street View</p>
  </div>
</div>
