import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[tbColumnDef]",
})
export class TbColumnDefDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set tbColumnDef(columnName: string) {}
}
