import { Pipe, PipeTransform } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { AddressModel } from "src/app/public/models/address.model";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Pipe({
  name: "getShortAddress",
})
export class GetShortAddressPipe implements PipeTransform {
  public cities: any[];
  public towns: any[];
  public districts: any[];

  constructor(private categoryService: CategoryService) {}

  transform(address: AddressModel): Observable<string> {
    if (address) {
      return combineLatest([
        this.categoryService.getAllCity(),
        this.categoryService.getAllTown(),
        this.categoryService.getAllDistrict(),
      ]).pipe(
        mergeMap((data: any) => {
          const _cities = data[0].find((t: any) => t.cKey === address.city);
          const _town = data[1].find((t: any) => t.cKey === address.town);
          const _districts = data[2].find(
            (t: any) => t.cKey === address.district
          );

          const fullDistrict = [_town?.cValue, _districts?.cValue]
            .filter(Boolean)
            .join(" - ");
          return of(
            [fullDistrict, _cities?.cValue].filter(Boolean).join(" - ")
          );
        })
      );
    } else {
      return of("");
    }
  }
}
