import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-form-real-estate-info",
  templateUrl: "./form-real-estate-info.component.html",
  styleUrls: ["./form-real-estate-info.component.scss"],
})
export class FormRealEstateInfoComponent implements AfterViewInit, OnChanges {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() isEdit: boolean;

  get formAddress() {
    return this.formRealEstate.get("address") as UntypedFormGroup;
  }

  // data
  public streets$: Observable<any>;
  public districts$: Observable<any>;
  public provinces$: Observable<any>;
  public towns$: Observable<any>;

  // control
  public districtsControl$: AbstractControl;
  public provincesControl$: AbstractControl;
  public townsControl$: AbstractControl;

  constructor(private categoryService: CategoryService) {
    this.streets$ = categoryService.getAllStreet() as Observable<any>;
    this.districts$ = categoryService.getAllDistrict() as Observable<any>;
    this.provinces$ = categoryService.getAllCity() as Observable<any>;
    this.towns$ = categoryService.getAllTown() as Observable<any>;
  }

  ngAfterViewInit(): void {
    //  Control
    this.townsControl$ = this.formRealEstate.controls["address"].get("town");
    this.districtsControl$ =
      this.formRealEstate.controls["address"].get("district");
    this.provincesControl$ =
      this.formRealEstate.controls["address"].get("city");

    // changed province/city
    this.provincesControl$.valueChanges.subscribe((data) => {
      this.getDistrict(data);
      this.townsControl$.reset();
      this.districtsControl$.reset();
    });

    // Change district
    this.districtsControl$.valueChanges.subscribe((data) => {
      this.getTown(data);
      this.townsControl$.reset();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { district, city } = this.formAddress.getRawValue();
    const { isEdit } = changes;

    if (!isEdit.currentValue) {
      this.formAddress.controls["city"].disabled;
    }

    this.getDistrict(city);
    this.getTown(district);
  }

  public eventSelectLocation($event: any): void {
    this.formAddress.patchValue({
      latitude: $event.lat,
      longitude: $event.lng,
    });
  }

  private getDistrict(city?: string): void {
    this.districts$ = this.categoryService.getAllDistrict().pipe(
      map((data) => {
        return data.filter((district: any) => district?.refValue === city);
      })
    );
  }

  private getTown(district: string): void {
    this.towns$ = this.categoryService.getAllTown().pipe(
      map((data) => {
        return data.filter((town: any) => town?.refValue === district);
      })
    );
  }

  // Convert URL StreetView
  public convertURL($event: any) {
    let linkURL = $event.target.value.match(/(?<=src=").*?(?=[\*"])/gm);
    linkURL !== null
      ? this.formRealEstate.controls["address"]
          .get("streetView")
          .setValue(linkURL[0])
      : this.formRealEstate.controls["address"].get("streetView").setValue("");
  }
}
