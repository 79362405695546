import { Pipe, PipeTransform } from "@angular/core";
import { UserService } from "src/app/public/services/user/user.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { APP_ACCOUNT_TYPE } from "src/app/public/constants/user.constant";

@Pipe({
  name: "invisiblePhoneNumber",
})
export class InvisiblePhoneNumberPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(value: any, ...args: any[]): Observable<any> {
    return this.userService.user$.pipe(
      map((data: { type: string }) => {
        if (data.type === APP_ACCOUNT_TYPE.SUPER_ADMIN) return value;
        return this.formatPhoneNumber(value);
      })
    );
  }
  private formatPhoneNumber(inputValue: string): string {
    // Xử lý logic ẩn số điện thoại ở đây
    const visibleDigits = 4;
    const hiddenDigits = 4;

    const visiblePart = inputValue?.substr(0, visibleDigits);
    const hiddenPart = "xxxx" + inputValue?.substr(-hiddenDigits);

    return visiblePart + hiddenPart;
  }
}
