<div class="table-responsive table-card">
  <table class="table" *ngIf="tableType === 'tableRealEstate'">
    <tbody>
      <tr *ngFor="let i of rowSkeleton">
        <th
          class="tg-cly1 d-flex align-items-center justify-content-start gap-3"
        >
          <div
            class="line"
            style="width: 30px; background: #80787826 !important"
          ></div>
          <div
            class="line"
            style="width: 30px; background: #80787826 !important"
          ></div>
          <div
            class="line"
            style="
              width: 40px;
              height: 20px;
              margin-left: 20px;
              margin-right: 20px;
            "
          ></div>
          <div class="line" style="height: 40px; margin-right: 20px">
            <div
              class="line"
              style="
                width: 75px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 300px; height: 60px; margin-right: 20px"
          >
            <div
              class="line"
              style="
                width: 200px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
            <div
              class="line"
              style="
                background: #80787826 !important;
                margin-left: 10px;
                width: 140px;
                margin-top: 6px !important;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 140px; height: 50px; margin-right: 10px"
          >
            <div
              class="line"
              style="
                width: 100px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 75px; height: 50px; margin-right: 10px"
          >
            <div
              class="line"
              style="
                width: 40px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 86px; height: 50px; margin-right: 10px"
          >
            <div
              class="line"
              style="
                width: 60px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 60px; height: 50px">
            <div
              class="line"
              style="
                width: 40px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 110px; height: 50px; margin-right: 10px"
          >
            <div
              class="line"
              style="
                width: 85px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 120px; height: 50px; margin-right: 10px"
          >
            <div
              class="line"
              style="
                width: 85px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line align-items-center d-flex justify-content-center gap-2"
            style="width: 140px; height: 50px; margin-right: 10px"
          >
            <div class="second-square"></div>
            <div class="second-square"></div>
            <div class="second-square"></div>
          </div>
          <div
            class="line align-items-center d-flex justify-content-center gap-2"
            style="width: 140px; height: 50px; margin-right: 10px"
          >
            <div class="second-square"></div>
            <div class="second-square"></div>
            <div class="second-square"></div>
          </div>
        </th>
      </tr>
    </tbody>
  </table>

  <table class="table" *ngIf="tableType === 'tableUser'">
    <tbody>
      <tr *ngFor="let i of rowSkeleton">
        <th class="d-flex align-items-center justify-content-start gap-3">
          <div class="text-line skeleton"></div>
        </th>
      </tr>
    </tbody>
  </table>
</div>
