import { Component, Input, OnInit } from "@angular/core";
import Vietnam from "flatpickr/dist/l10n/vn.js";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  USER_STATUS_FILTER,
  USER_TYPE_FILTER,
} from "src/app/public/constants/user.constant";

@Component({
  selector: "app-search-filter-customer",
  templateUrl: "./search-filter-customer.component.html",
  styleUrls: ["./search-filter-customer.component.scss"],
})
export class SearchFilterCustomerComponent implements OnInit {
  public USER_STATUS = USER_STATUS_FILTER;
  public USER_TYPE = USER_TYPE_FILTER;

  @Input() searchCustomerParams: UntypedFormGroup;
  public formRange: UntypedFormGroup;

  public language: any = Vietnam.vn;
  public formatEndDate: string;
  public formatStartDate: string;
  public maxDate = new Date();

  constructor(private fb: UntypedFormBuilder) {
    this.formRange = this.fb.group({
      startTo: [""],
      endTo: [""],
    });
  }

  ngOnInit(): void {
    this.formRange.valueChanges.subscribe((data) => {
      const startDate = data.startTo;
      const endDate = data.endTo;

      if (startDate && endDate) {
        this.formatStartDate = this.formatDate(startDate);
        this.formatEndDate = this.formatDate(endDate);
      }
    });
  }

  public getControl(control: string): UntypedFormControl {
    return this.searchCustomerParams.controls[control] as UntypedFormControl;
  }

  public onClickSubmitSearch() {
    if (this.formatEndDate && this.formatStartDate) {
      this.getControl("startDate").setValue(this.formatStartDate);
      this.getControl("endDate").setValue(this.formatEndDate);
    }
  }

  public handleReset() {
    this.formRange.reset();
    this.getControl("startDate").reset();
    this.getControl("endDate").reset();
    this.formatStartDate = null;
    this.formatEndDate = null;
  }

  private formatDate(date: Date): string {
    return new Date(date).toLocaleDateString("en-CA");
  }
}
