import { catchError, reduce, tap } from "rxjs/operators";
import { UserHttpService } from "./user-http.service";
import { StorageService } from "src/app/public/services/storage/storage.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserModel } from "../../models/user.model";
import { USER_INFO } from "../../constants/common.constant";
import { HistoryViewUser } from "../../models/form-history-view-user.model";
import { API_URL } from "../../constants/api-url.constant";
import { RealEstateModel } from "../../models/real-estate.model";
import { DivisionModel } from "../../models/division.model";
import { GroupModel } from "../../models/group.model";
import { PositionModel } from "../../models/position.model";
import { EmployeeModel } from "../../models/employee.model";

@Injectable()
export class UserService {
  private _user$: BehaviorSubject<UserModel> =
    new BehaviorSubject<UserModel | null>(null);

  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  constructor(
    private storageService: StorageService,
    private userHttp: UserHttpService,
    private router: Router
  ) {}

  /**
   * @function: Get current user
   */
  get user$(): BehaviorSubject<UserModel> {
    const _user = this.storageService.get(USER_INFO);

    if (!_user || Object.keys(_user).length === 0) {
      this.getUserLogin();
    } else {
      this.setUser(_user);
    }

    return this._user$ as BehaviorSubject<UserModel>;
  }

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  // GET LIST USER CUSTOMER
  public getListUserCustomers(params?: any): Observable<UserModel[]> {
    return this.userHttp.getListUser(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _limit = params?.limit;
        const _pageSize = data?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
      })
    ) as Observable<any>;
  }

  // GET DETAIL USER (CUSTOMER)
  public getDetailUserCustomer(id: string | number): Observable<UserModel> {
    return this.userHttp.getDetailUser(id).pipe(
      reduce((_: any, cur: any) => {
        return { ...cur?.success };
      }, {})
    );
  }

  // GET HISTORY VIEW USER (CUSTOMER)
  public getListHistoryViewUserCustomer(
    id: string | number,
    params: any
  ): Observable<HistoryViewUser> {
    return this.userHttp.getListHistoryViewUserCustomer(id, params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _limit = params?.limit;
        const _pageSize = data?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
      })
    ) as Observable<HistoryViewUser>;
  }

  // APPROVAL USER (CUSTOMER)
  public approvalStatusUserCustomer(payload: any): Observable<any> {
    return this.userHttp.approveUserCustomer(payload);
  }

  // REJECT USER (CUSTOMER)
  public rejectStatusUserCustomer(payload: any): Observable<any> {
    return this.userHttp.rejectUserCustomer(payload);
  }

  // INACTIVE
  public inactiveUser(payload: any): Observable<any> {
    return this.userHttp.inactiveUser(payload);
  }

  // INACTIVE
  public activeUser(payload: any): Observable<any> {
    return this.userHttp.activeUser(payload);
  }

  /**
   * @function: Set data for current user.
   */
  public setUser(data: UserModel) {
    this._user$.next(data || null);
  }

  private getUserLogin(): void {
    this.userHttp
      .getUserLogin()
      .pipe(
        catchError((error) => {
          this.handleGetCurrentLoginFailed(error);
          return throwError(error);
        }),
        reduce((curr: any, pre: any) => {
          return { ...pre?.item };
        }, {}),
        tap((data: any) => {
          this.handleGetCurrentLoginSuccess(data);
        })
      )
      .subscribe();
  }

  private handleGetCurrentLoginSuccess(data: UserModel): void {
    this.storageService.set(USER_INFO, data);
    this.setUser(data);
  }

  private handleGetCurrentLoginFailed(errors: any): void {
    this.storageService.deleteAll();
    this.router.navigate([API_URL.LOGIN]);
  }

  // GET LIST USER AGENTS
  public getListUserAgents(params: any): Observable<UserModel[]> {
    return this.userHttp.getListAgent(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _limit = params?.limit;
        const _pageSize = data?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
      })
    );
  }

  public getRealEstatesUploaded(
    id: string | number,
    params: any
  ): Observable<RealEstateModel[]> {
    return this.userHttp.getRealEstatesUploaded(id, params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _limit = params?.limit;
        const _pageSize = data?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
      })
    );
  }

  public getListUserName(phoneNumbers: {
    phoneNumbers: string[];
  }): Observable<any> {
    return this.userHttp.getListUserName(phoneNumbers);
  }

  //DIVISION
  public getListDivision(): Observable<DivisionModel[]> {
    return this.userHttp.getListDivision();
  }

  public createDivision(payload: DivisionModel): Observable<DivisionModel> {
    return this.userHttp.createDivision(payload);
  }

  public getDetailDivision(id: number): Observable<DivisionModel> {
    return this.userHttp.getDetailDivision(id);
  }

  public updateDivision(id: number, payload: DivisionModel) {
    return this.userHttp.updateDivision(id, payload);
  }

  public deleteDivision(id: number) {
    return this.userHttp.deleteDivision(id);
  }

  //GROUP
  public getListGroup(): Observable<GroupModel[]> {
    return this.userHttp.getListGroup();
  }

  public createGroup(payload: GroupModel): Observable<GroupModel> {
    return this.userHttp.createGroup(payload);
  }

  public getDetailGroup(id: number): Observable<GroupModel> {
    return this.userHttp.getDetailGroup(id);
  }

  public updateGroup(id: number, payload: GroupModel) {
    return this.userHttp.updateGroup(id, payload);
  }

  public deleteGroup(id: number) {
    return this.userHttp.deleteGroup(id);
  }

  //POSITION
  public getListPosition(): Observable<PositionModel[]> {
    return this.userHttp.getListPosition();
  }

  //EMPLOYEE
  public getListEmployee(params: any): Observable<EmployeeModel[]> {
    return this.userHttp.getListEmployee(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _limit = params?.limit;
        const _pageSize = data?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
      })
    );
  }

  public createEmployee(payload: EmployeeModel): Observable<EmployeeModel> {
    return this.userHttp.createEmployee(payload);
  }

  public getDetailEmployee(id: number): Observable<EmployeeModel> {
    return this.userHttp.getDetailEmployee(id);
  }

  public updateEmployee(id: number, payload: EmployeeModel) {
    return this.userHttp.updateEmployee(id, payload);
  }

  public deleteEmployee(id: number) {
    return this.userHttp.deleteEmployee(id);
  }

  public activeEmployee(ids: number[]) {
    return this.userHttp.activeEmployee(ids);
  }

  public deactivateEmployee(ids: number[]) {
    return this.userHttp.deactivateEmployee(ids);
  }
}
