import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { UntypedFormGroup } from "@angular/forms";
import { DecrementFormControl } from "src/app/public/utils/decrement-form-control";
import { IncrementFormControl } from "src/app/public/utils/increment-form-control";

@Component({
  selector: "app-form-furniture",
  templateUrl: "./form-furniture.component.html",
  styleUrls: ["./form-furniture.component.scss"],
})
export class FormFurnitureComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() isEdit: boolean;

  statusFurniture$: Observable<any>;
  floorMaterials$: Observable<any>;

  decrement = DecrementFormControl;
  increment = IncrementFormControl;

  constructor(private categoryService: CategoryService) {
    this.statusFurniture$ = categoryService.getAllStatusFurniture();
    this.floorMaterials$ = categoryService.getAllFloorMaterial();
  }
}
