import { Pipe, PipeTransform } from "@angular/core";
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";

@Pipe({
  name: "getShortStatus",
})
export class GetShortStatusPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    switch (value) {
      case LAND_STATUS.REJECTED:
        return "Từ chối";
      case LAND_STATUS.NEW:
        return "Chờ duyệt";
      case LAND_STATUS.DRAFT:
        return "Bản Nháp";
      default:
        return "Đã duyệt";
    }
  }
}
