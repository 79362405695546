import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";

@Directive({
  selector: "[invisiblePhoneNumber]",
})
export class InvisiblePhoneNumberDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private control: NgControl,
    private authorization: AuthorizationService
  ) {}

  @HostListener("input", ["$event"])
  onInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.el.nativeElement.value = this.handleHiddenNumber(inputValue);
  }

  ngOnInit(): void {
    const value = this.control.value;
    if (value) {
      this.el.nativeElement.value = this.handleHiddenNumber(this.control.value);
    } else {
      this.control.valueChanges.subscribe((data) => {
        this.el.nativeElement.value = this.handleHiddenNumber(data);
      });
    }
  }

  private handleHiddenNumber(value: string): string {
    const hasPermission = this.authorization.isSuperAdmin();
    if (hasPermission) return value;
    return this.formatPhoneNumber(value);
  }

  private formatPhoneNumber(inputValue: string): string {
    // Xử lý logic ẩn số điện thoại ở đây
    const visibleDigits = 4;
    const hiddenDigits = 4;

    const visiblePart = inputValue.substr(0, visibleDigits);
    const hiddenPart = "xxxx" + inputValue.substr(-hiddenDigits);

    return visiblePart + hiddenPart;
  }
}
