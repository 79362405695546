import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Component({
  selector: "app-form-exterior",
  templateUrl: "./form-exterior.component.html",
  styleUrls: ["./form-exterior.component.scss"],
})
export class FormExteriorComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() isEdit: boolean;

  balconyDirection$: Observable<any>;
  constructor(private categoryService: CategoryService) {
    this.balconyDirection$ = categoryService.getAllDirection();
  }
}
