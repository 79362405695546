<!--FORM SEARCH BẤT ĐỘNG SẢN THƯỜNG-->
<form (keydown.enter)="$event.preventDefault()">
  <div class="d-flex flex-wrap justify-content-between">
    <div class="row gx-1 col-xl-9 col-lg-12 col-sm-12 flex-wrap">
      <div class="w-sm position-relative col-xl-3 col-lg-4 col-sm-12">
        <app-search-suggestion [searchForm]="searchLandParams">
        </app-search-suggestion>
      </div>
      <div class="search-box col-xl-2 col-lg-4 col-sm-6">
        <app-filter-range
          title="Mặt tiền"
          unit="m"
          [fromControl]="getControl('frontWidthFrom')"
          [toControl]="getControl('frontWidthTo')"
        ></app-filter-range>
      </div>

      <div class="search-box col-xl-2 col-lg-4 col-sm-6">
        <app-filter-range
          [fromControl]="getControl('acreageFrom')"
          [toControl]="getControl('acreageTo')"
          title="Diện tích"
          unit="㎡"
        ></app-filter-range>
      </div>
    </div>

    <div class="d-flex action-search-container gap-1">
      <button class="btn btn-primary" (click)="onShowFullSearchModal()">
        <i class="mdi mdi-filter-outline"></i>
        Bộ lọc nâng cao
      </button>
      <button class="btn btn-primary" (click)="clearFormRealEstate()">
        <i class="mdi mdi-restore"></i> Đặt lại
      </button>
      <div>
        <div class="dropdown" ngbDropdown>
          <button
            class="btn btn-primary dropdown arrow-none"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <i class="ri-more-fill"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <ng-container
              *hasPermissionAction="
                PERMISSION_ACTIONS.IMPORT_PDF;
                resource: REAL_ESTATE_RESOURCES.WAITING_APPROVE
              "
            >
              <li>
                <input
                  #inputFileXlsx
                  type="file"
                  multiple="false"
                  class="file-upload d-none"
                  (change)="onEventFileSelected($event)"
                />
                <a
                  (click)="inputFileXlsx.click()"
                  class="dropdown-item d-flex align-items-center"
                  type="button"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                >
                  <i class="bx bx-upload me-2"></i> Upload file
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</form>
