<div class="position-relative d-flex h-100">
  <!-- Start Breadcrumbs -->
  <div class="w-100" [class.dialog-input-data]="dialogInputData">
    <app-breadcrumbs
      title="Chi tiết BĐS"
      [breadcrumbItems]="BREAD_CRUMB_ITEMS"
    ></app-breadcrumbs>

    <!-- End -->
    <br />
    <!--end row-->
    <div class="row h-100" *ngIf="productDetail$ | async as product">
      <div class="col-lg-12 h-100">
        <div class="shadow-none mb-lg-0 h-100">
          <div class="h-100">
            <div class="row gx-lg-4 h-100 pb-5">
              <div class="col-xl-4 h-100 overflow-scroll">
                <div class="card">
                  <div class="card-body ribbon-box">
                    <div
                      class="ribbon round-shape"
                      ngClass="ribbon-{{ product.status | getStatusColor }}"
                    >
                      <span>{{ product.status | getStatusText }}</span>
                    </div>
                    <div class="product-img-slider mt-4">
                      <app-slider-image-detail
                        [photos]="product"
                        [isEdit]="isEdit"
                        (onDeleteImage)="eventDeleteImage($event)"
                        (onUpdateImage)="eventEditImage($event)"
                        (onAddImage)="eventAddImage()"
                        (onSelectImgFront)="eventSelectImgFront($event)"
                        (onSelectedImage)="
                          onOpenModalSlider($event, product.photos)
                        "
                      >
                      </app-slider-image-detail>
                    </div>
                    <app-form-common-info
                      [isEdit]="isEdit"
                      [formCommon]="formRealEstate"
                    ></app-form-common-info>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-xl-8 overflow-scroll h-100">
                <div class="card">
                  <div class="row card-body">
                    <ul
                      ngbNav
                      #nav="ngbNav"
                      [(activeId)]="activeTab"
                      (click)="onTabChange()"
                      class="nav nav-tabs-custom border-bottom-0 nav-success mb-3"
                    >
                      <li [ngbNavItem]="'general'" class="nav-item">
                        <a ngbNavLink
                          ><i class="ri-home-5-line align-middle me-1"></i>
                          Thông tin chung
                        </a>
                        <ng-template ngbNavContent>
                          <div class="d-flex">
                            <app-real-estate-info
                              [product]="product"
                              [isEdit]="isEdit"
                              [formRealEstate]="formRealEstate"
                              (copyEvent)="onCopy()"
                              (cancelChangeEvent)="onCancelChanged()"
                              (saveEditEvent)="onSave()"
                              (editEvent)="onClickEdit()"
                            ></app-real-estate-info>
                          </div>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="'history'" class="nav-item">
                        <a ngbNavLink
                          ><i class="ri-time-line align-middle me-1"></i>
                          Lịch sử chỉnh sửa
                        </a>
                        <ng-template ngbNavContent>
                          <app-real-estate-edit-history
                            [realEstateId]="dialogInputData?.id || id"
                          ></app-real-estate-edit-history>
                        </ng-template>
                      </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                      <div [ngbNavOutlet]="nav"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <div class="btn-close-dialog-main">
    <button
      *ngIf="dialogInputData"
      type="button"
      class="btn-close-dialog"
      data-dismiss="modal"
      aria-label="Close"
      ngbTooltip="Đóng chi tiết"
      (click)="dialogService.closeAll()"
    >
      <i class="ri-close-line"></i>
    </button>
  </div>
</div>
