import { Pipe, PipeTransform } from "@angular/core";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";

@Pipe({
  name: "landPurpose",
  pure: true
})
export class LandPurposePipe implements PipeTransform {
  transform(value: number | string, ...args: any[]): string {

    return this.getLandPurpose(value);
  }

  getLandPurpose(value: number | string): string {
    switch (value) {
      case LAND_PURPOSE.FOR_RENT:
        return "Cho Thuê";
      case LAND_PURPOSE.FOR_SELL:
        return "Bán";
      default:
        return "Chưa XĐ";
    }
  }
}
