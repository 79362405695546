import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollspyDirective } from "./scrollspy.directive";
import { TbColumnDefDirective } from "./tb-column-def.directive";
import { IsNewRealEstateDirective } from "./is-new-real-estate.directive";
import { ImagePreviewDirective } from "src/app/public/directives/image-preview.directive";
import { TbCellDefDirective } from "src/app/public/directives/tb-cell-def.directive";
import { InvisiblePhoneNumberDirective } from "./invisible-phone-number.directive";
import { VideoPreviewDirective } from "./video-preview.directive";
import { GetOwnerRealEstateDirective } from "./get-owner-real-estate.directive";
import { GetNameByIdDirective } from "./get-name-by-id.directive";
import { GetPermissionNamesDirective } from "./get-permission-names.directive";
import { CheckPermissionServiceDirective } from "src/app/public/directives/check-permission-service.directive";
import { CheckPermissionActionDirective } from "./check-permission-action.directive";
import { CheckPermissionResourceDirective } from "./check-permission-resource.directive";
import { RealEstateAllowToSellDirective } from "src/app/public/directives/real-estate-allow-to-sell.directive";
import { BtnCheckRealEstateMarkDirective } from "src/app/public/directives/real-estate-mark.directive";
import { PercentageDirective } from "./percentage.directive";
import { CompareEditFieldDirective } from "./compare-edit-field.directive";
import { HighlightSystemDirective } from "./highlight-system.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    ScrollspyDirective,
    TbColumnDefDirective,
    IsNewRealEstateDirective,
    ImagePreviewDirective,
    TbCellDefDirective,
    InvisiblePhoneNumberDirective,
    VideoPreviewDirective,
    GetOwnerRealEstateDirective,
    GetNameByIdDirective,
    GetPermissionNamesDirective,
    CheckPermissionServiceDirective,
    CheckPermissionResourceDirective,
    CheckPermissionActionDirective,
    RealEstateAllowToSellDirective,
    BtnCheckRealEstateMarkDirective,
    PercentageDirective,
    CompareEditFieldDirective,
    HighlightSystemDirective
  ],
  exports: [
    ScrollspyDirective,
    IsNewRealEstateDirective,
    ImagePreviewDirective,
    TbCellDefDirective,
    InvisiblePhoneNumberDirective,
    VideoPreviewDirective,
    GetOwnerRealEstateDirective,
    GetNameByIdDirective,
    GetPermissionNamesDirective,
    CheckPermissionServiceDirective,
    CheckPermissionResourceDirective,
    CheckPermissionActionDirective,
    RealEstateAllowToSellDirective,
    BtnCheckRealEstateMarkDirective,
    PercentageDirective,
    CompareEditFieldDirective,
    HighlightSystemDirective
  ],
})
export class HLDirectivesModule {}
