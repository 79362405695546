import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  REAL_ESTATE_STATUS,
  REAL_ESTATE_STATUS_SUMMARY,
} from "src/app/public/constants/real-estate.constant";
import { RealEstateModel } from "src/app/public/models/real-estate.model";

@Component({
  selector: "app-real-estate-info",
  templateUrl: "./real-estate-info.component.html",
  styleUrls: ["./real-estate-info.component.scss"],
})
export class RealEstateInfoComponent {
  readonly REAL_ESTATE_STATUS = REAL_ESTATE_STATUS;
  readonly REAL_ESTATE_STATUS_SUMMARY = REAL_ESTATE_STATUS_SUMMARY;

  @Output() copyEvent = new EventEmitter();
  @Output() cancelChangeEvent = new EventEmitter();
  @Output() saveEditEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();

  @Input() formRealEstate: FormGroup;
  @Input() product: RealEstateModel;
  @Input() isEdit: boolean;
}
