import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationStart,
  ParamMap,
  Router,
} from "@angular/router";
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { PolygonAreaService } from "src/app/public/services/google-maps/polygon-area.service";
import { combineLatest, of, Subject } from "rxjs";
import { MapAbstract } from "src/app/components/map-real-estate/map.constant";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { StorageService } from "src/app/public/services/storage/storage.service";
import { MapState } from "src/app/public/services/google-maps/map.state";
import { MapSyncDataRealEstateService } from "src/app/public/services/google-maps/map-sync-data-real-estate.service";
import { RealEstateMaker } from "src/app/public/models/real-estate-maker.mode";
import { PAGE_DEFAULT } from "src/app/public/constants/common.constant";
import { LatLngBounds } from "@agm/core/services/google-maps-types";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "src/app/public/services/common/event-bus.service";
import { RealEstateMarkService } from "src/app/public/services/real-estate/real-estate-mark.service";
import {
  REAL_ESTATE_MARK_FILTER,
  REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED,
  REAL_ESTATE_URL,
  STATUS_IN_MAP_FOR_APPROVED_PAGE,
} from "src/app/public/constants/real-estate.constant";
import { RealEstateDetailComponent } from "src/app/pages/real-estate-management/real-estate-detail/real-estate-detail.component";
import { MatDialog } from "@angular/material/dialog";

enum MAPS {
  CLEAR_BORDER = "btn_clear_border",
  RECOMMEND_SCROLL = "recommend_scroll",
  SEARCH_FILTER_BAR = "search_filter_bar",
  ZOOM_CONTROL = "zoom_control",
  REAL_ESTATES = "real_estates",
  MAP_BUTTONS = "map_buttons",
}

enum MAP_MODE {
  DRAW = "draw",
  ZOOM = "zoom",
  NORMAl = "normal",
}

@Component({
  selector: "app-map-real-estate",
  templateUrl: "./map-real-estate.component.html",
  styleUrls: ["./map-real-estate.component.scss"],
})
export class MapRealEstateComponent
  extends MapAbstract
  implements OnInit, OnDestroy
{
  @Output() landSelected = new EventEmitter();
  @Output() showAllLayoutMapEvent = new EventEmitter();
  @Output() showFullMapEvent = new EventEmitter();
  @Input() formSearchAndFilter: UntypedFormGroup;
  @Input() isShowRealEstates: boolean;

  @ViewChild("agmMapRef") agmMapRef: any;

  public STATUS_APPROVED = REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED;
  public REAL_ESTATE_URL = REAL_ESTATE_URL;
  public REAL_ESTATE_MARK = REAL_ESTATE_MARK_FILTER;

  public lat: number;
  public lng: number;
  public zoom: number;
  public gridSize = 10;
  public isLoading$: Observable<boolean>;
  public mapEvent: any;
  public show: boolean;
  public isShowMarkerPoint: boolean = true;

  public subscriptions$ = new Subject();
  public mapReady$: Subject<boolean>;
  public address$: Observable<any>;
  public location$: Observable<any>;
  public area$: Observable<any>;
  public boundsChange$: Subject<LatLngBounds>;
  public pointCenter: { lat: number; lng: number };
  public makersHover$: Observable<any>;

  public isShowConfirm: boolean = false;
  public isShowLand: boolean = false;
  public polygonPts: any;
  public drawing: boolean = false;
  public poly: google.maps.Polyline;
  public mode: string = "normal";
  public zoom$: Observable<number>;

  public previousMaker: any = null;

  public makers$: Observable<RealEstateMaker[]>;
  public realEstatesPaging$: Observable<any>;
  public showListInMap: boolean = true;
  public showFullListInMap: boolean = false;
  public showOverlay: boolean = false;

  constructor(
    private router: Router,
    private polygonService: PolygonAreaService,
    public route$: ActivatedRoute,
    private storageService: StorageService,
    private mapState: MapState,
    private realEstateStorage: MapSyncDataRealEstateService,
    private eventBus: EventBusService,
    private realEstateMarkService: RealEstateMarkService,
    private dialog: MatDialog
  ) {
    super();
    this.polygonService
      .onArea()
      .pipe(takeUntil(this.subscriptions$))
      .subscribe((data) => {
        this.polygonPts = data;
      });
    this.address$ = route$.queryParamMap.pipe(
      map((params: ParamMap) => params.get("address"))
    );
    this.location$ = this.polygonService.onLocation();
    this.area$ = this.polygonService.onArea();
    this.mapReady$ = new Subject();
    this.makers$ = realEstateStorage.getMakers();
    this.boundsChange$ = new Subject<any>();
    this.zoom$ = polygonService.onZoom();
    this.realEstatesPaging$ = this.realEstateStorage.getRealEstatePaging();
    this.isLoading$ = this.realEstateStorage.getLoading();
  }

  ngOnInit(): void {
    this.observeLocationChange();
    this.observeAddressChange();
    this.onHandleRefresh();
    this.observerBoundChange();
    this.observerRealEstateHover();
    this.observerZoomInit();
    this.observerRealEstatesMark();
    this.notAllowPatchEmptyStatus();
  }

  public getControl(name: string) {
    return this.formSearchAndFilter.get(name) as UntypedFormControl;
  }

  public observerRealEstatesMark() {
    let status = new Subject();
    this.eventBus.on(EVENT_BUS_EVENTS.REAL_ESTATE_MARK).subscribe((event) => {
      if (event) {
        status = new Subject();
        this.realEstateMarkService.realEstatesMark$
          .pipe(takeUntil(status))
          .subscribe((data) => {
            this.makers$ = new Observable<RealEstateMaker[]>((o) => {
              const makerRealEstatesMark = data?.map(
                (realEstate: {
                  photos: any;
                  address: { latitude: any; longitude: any };
                  purpose: any;
                  id: any;
                  toilets: any;
                  bedrooms: any;
                  acreage: any;
                  frontWidth: any;
                  price: any;
                }) => {
                  return {
                    photos: realEstate?.photos,
                    latitude: realEstate?.address?.latitude,
                    longitude: realEstate?.address?.longitude,
                    purpose: realEstate?.purpose,
                    id: realEstate?.id,
                    toilets: realEstate?.toilets,
                    bedrooms: realEstate?.bedrooms,
                    acreage: realEstate?.acreage,
                    frontWidth: realEstate?.frontWidth,
                    price: realEstate?.price,
                    address: realEstate?.address,
                  } as RealEstateMaker;
                }
              );
              o.next(makerRealEstatesMark);
            });
            this.realEstatesPaging$ = new Observable<any>((observable) => {
              observable.next({ data });
            });
          });
      } else {
        status.next();
        status.complete();
        this.makers$ = this.realEstateStorage.getMakers();
        this.realEstatesPaging$ = this.realEstateStorage.getRealEstatePaging();
      }
    });
  }

  public onHandlerMouseOut() {
    try {
      this.previousMaker?.close();
    } catch (_) {}
  }

  public onHandleBoundsChange(bounds: any) {
    this.boundsChange$.next(bounds);
  }

  // method handler.
  // Chạy khi Map đã init.
  public mapReady(event: any): void {
    this.mapEvent = event;
    this.mapReady$.next(true);
    this.initMapContent();
  }

  private initMapContent() {
    this.showElementForAgmMap(
      google.maps.ControlPosition.LEFT_TOP,
      MAPS.RECOMMEND_SCROLL
    );
    this.showElementForAgmMap(
      google.maps.ControlPosition.TOP_LEFT,
      MAPS.SEARCH_FILTER_BAR
    );
    this.showElementForAgmMap(
      google.maps.ControlPosition.RIGHT_BOTTOM,
      MAPS.ZOOM_CONTROL
    );

    this.showElementForAgmMap(
      google.maps.ControlPosition.LEFT_CENTER,
      MAPS.REAL_ESTATES
    );

    this.showElementForAgmMap(
      google.maps.ControlPosition.TOP_RIGHT,
      MAPS.MAP_BUTTONS
    );
  }

  public onHandlerMouseOver(infoWindow: any) {
    if (this.previousMaker) {
      this.onHandlerMouseOut();
    }
    this.previousMaker = infoWindow;
    infoWindow.open();
  }

  @HostListener("window:beforeunload")
  onBeforeUnload() {
    this.storageService.set("LOCATION_MAP", {
      lat: this.pointCenter.lat,
      lng: this.pointCenter.lng,
      zoom: this.zoom,
    });
  }

  public onHandleRefresh() {
    this.router.events
      .pipe(takeUntil(this.subscriptions$))
      .subscribe((event) => {
        if (event instanceof NavigationStart && event.id === 4) {
          const location = this.storageService.get("LOCATION_MAP");
          this.formSearchAndFilter.patchValue({
            cenLat: location?.["lat"],
            cenLng: location?.["lng"],
            zoom: location?.["zoom"],
          });
        }
      });
  }

  public onHandleEventZoomChange($zoomEvent: number) {
    this.show = $zoomEvent <= 12;
    if ($zoomEvent > 12) {
      // nếu Zoom level đat thì lấy các BĐS trong khoảng diện tích
    } else {
      this.gridSize = 50;
    }
  }

  public onHandleCenterChange(event: any) {
    this.pointCenter = event;
  }

  public onClickRemovePolygon() {
    this.poly?.setMap(null);
    this.isShowLand = false;
    this.mode = "normal";

    this.formSearchAndFilter.patchValue({
      page: PAGE_DEFAULT,
      limit: 80,
    });
    this.mapState.polygon$ = null;
    this.mapState.allowCallApi$ = true;
  }

  public trackByIndex(index: any, item: any) {
    return item.id;
  }

  public onClickResetSearch(): void {
    this.formSearchAndFilter.controls["address"].patchValue("");
    this.formSearchAndFilter.controls["city"].reset();
    this.formSearchAndFilter.controls["town"].reset();
    this.formSearchAndFilter.controls["district"].reset();
    this.formSearchAndFilter.controls["street"].reset();
  }

  private observerZoomInit(): void {
    this.zoom$.pipe().subscribe((zoom) => (this.zoom = zoom));
  }

  public onClickApplySearch() {
    this.isShowConfirm = false;
    this.drawing = false;
    this.poly ? (this.isShowLand = true) : (this.isShowLand = false);
    this.handleEnabledControlMap();

    let list: any[] = [];
    this.poly?.getPath()?.forEach((p: any) => {
      list.push({
        lat: p.lat(),
        lng: p.lng(),
      });
    });
    this.mapState.polygon$ = list;
    this.mapState.allowCallApi$ = true;
    this.makers$ = this.realEstateStorage.getMakers();
  }

  public onClickMaker(infoWindow: any) {
    infoWindow.close();
  }

  public trackPolygon(hero: any) {
    return hero ? hero.id : undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions$.next();
    this.subscriptions$.complete();
    this.storageService.delete("LOCATION_MAP");
  }

  private showElementForAgmMap(position: number, field: string): void {
    if (!this.mapEvent.controls[position].getAt(0)) {
      this.mapEvent.controls[position].push(document.getElementById(field));
    }

    const fieldElement: HTMLElement = document.getElementById(field);

    if (fieldElement) {
      fieldElement.style.display = "block";
    }
  }

  private static hiddenElementInAgmMap(filed: string): void {
    const fieldElement: HTMLElement = document.getElementById(filed);

    if (fieldElement) {
      fieldElement.style.display = "none";
    }
  }

  private observeLocationChange(): void {
    this.location$
      .pipe(takeUntil(this.subscriptions$))
      .subscribe((location) => this.handleLocationChange(location));
  }

  private observeAddressChange(): void {
    combineLatest(this.mapReady$, this.address$)
      .pipe(takeUntil(this.subscriptions$))
      .subscribe(([isMapReady, address]) => {
        // Xóa bỏ polygon khi tiến hành tim kiếm bằng text.
        if (address) this.onClickRemovePolygon();
        isMapReady && this.handleAddressChange(address);
      });
  }

  private handleLocationChange(data: any) {
    setTimeout(() => {
      if (data) {
        this.lat = Number(data?.lat);
        this.lng = Number(data?.lng);
        return;
      } else {
        const location = this.storageService.get("LOCATION_MAP");

        this.lat = location?.["lat"] || this.POSITION_DEFAULT.lat;
        this.lng = location?.["lng"] || this.POSITION_DEFAULT.lng;
        this.zoom = location?.["zoom"] || 7;
      }
    }, 0);
    this.lat = 0;
    this.lng = 0;
  }

  public onClickDrawPolygon() {
    this.mapState.allowCallApi$ = false;
    this.formSearchAndFilter.patchValue({
      address: "",
      city: "",
      district: "",
      war: "",
    });

    const mapInstance =
      this.agmMapRef._mapsWrapper.getNativeMap().__zone_symbol__value;

    this.drawing = true;
    this.isShowConfirm = true;

    this.handleDisabledControlMap();

    google.maps.event.addDomListener(mapInstance, "mousedown", () => {
      this.handleDrawFreeHand(mapInstance);
    });
    this.makers$ = new Observable<RealEstateMaker[]>(null);

    this.mode = "draw";
  }

  public onClickRemoveBorder() {
    this.polygonPts = [];
    this.mode = MAP_MODE.NORMAl;
    this.mapState.polygon$ = null;
    this.onClickResetSearch();
  }

  public onClickCancelActionDraw() {
    this.isShowConfirm = false;
    this.drawing = false;
    this.poly?.setMap(null);
    this.handleEnabledControlMap();
    this.makers$ = this.realEstateStorage.getMakers();
  }

  private observerBoundChange() {
    const delayTime = 600; //ms
    const radiusAllowSearch = 6; //km

    this.boundsChange$
      .pipe(
        takeUntil(this.subscriptions$),
        debounceTime(delayTime),
        filter((b) => b !== null),
        switchMap((data: LatLngBounds) => {
          this.mapState.boundaries$ = data;
          this.formSearchAndFilter.patchValue({
            page: 1,
          });

          this.handleStateCallApi(this.getDistance(data) <= radiusAllowSearch);

          return of(data);
        })
      )
      .subscribe();
  }

  private notAllowPatchEmptyStatus() {
    this.formSearchAndFilter?.valueChanges.subscribe((params) => {
      if (!params?.status?.length) {
        this.handlePatchStatus();
      }
    });
  }

  private handlePatchStatus() {
    this.formSearchAndFilter.patchValue({
      status: STATUS_IN_MAP_FOR_APPROVED_PAGE,
    });
  }

  private handleAddressChange(address: string) {
    if (address) {
      this.isShowMarkerPoint = true;

      const [lat, lng] = address
        .split(",")
        .map((coord: string) => +coord.trim());

      if (lat && lng) {
        this.polygonPts = [];
        this.zoom = 18;
      }
    } else {
      // xoá bỏ border.
      this.polygonService.setArea = null;
      // hidden not clear
      MapRealEstateComponent.hiddenElementInAgmMap(MAPS.CLEAR_BORDER);
      // bỏ marker point
      this.isShowMarkerPoint = false;
    }
  }

  private handleDrawFreeHand(mapInstance: any) {
    this.poly = new google.maps.Polyline({
      map: mapInstance,
      clickable: false,
      strokeColor: "#62aef7",
    });

    const move = google.maps.event.addListener(
      mapInstance,
      "mousemove",
      (event: any) => {
        this.poly.getPath().push(event.latLng);
      }
    );

    google.maps.event.addListenerOnce(mapInstance, "mouseup", () => {
      google.maps.event.removeListener(move);
      const path = this.poly.getPath();

      this.poly.setMap(null);
      this.poly = new google.maps.Polygon({
        map: mapInstance,
        paths: path,
        strokeColor: "#62aef7",
        fillColor: "#62aef7",
        fillOpacity: 0.35,
      });

      google.maps.event.clearListeners(mapInstance, "mousedown");
      this.drawing = false;
    });
  }

  public handleDisabledControlMap() {
    const typeControlBtns = this.getElements(".gmnoprint");
    const mapContent = this.getElements(".map-content");

    typeControlBtns.forEach((btn) => {
      btn.style.opacity = "0";
    });
    mapContent.forEach((c) => {
      c.style.opacity = "0";
    });
  }

  public handleEnabledControlMap() {
    const typeControlBtns = this.getElements(".gmnoprint");
    const mapContent = this.getElements(".map-content");

    typeControlBtns.forEach((btn) => {
      btn.style.opacity = "1";
    });
    mapContent.forEach((c) => {
      c.style.opacity = "1";
    });
  }

  private handleStateCallApi(state: boolean): void {
    this.mapState.allowCallApi$ = state;
  }

  private getElements(className: string): NodeListOf<HTMLElement> {
    return document.querySelectorAll(className);
  }

  private observerRealEstateHover() {
    this.makersHover$ = this.eventBus.on("HOVER_REAL_ESTATE");
  }

  public onZoomIn() {
    this.mapEvent.setZoom(this.mapEvent.getZoom() + 1);
  }

  public onZoomOut() {
    this.mapEvent.setZoom(this.mapEvent.getZoom() - 1);
  }

  public handleSelectedRealEstate(id: number) {
    this.dialog.open(RealEstateDetailComponent, {
      data: {
        id,
      },
      panelClass: "modal-detail-realestate",
      width: "1300px",
      height: "90%",
      disableClose: true,
    });
  }

  public pageChange(page: number) {
    this.formSearchAndFilter.patchValue({
      page,
    });
  }

  public onToggleListInMap() {
    this.showListInMap = !this.showListInMap;
    if (!this.showListInMap && this.showFullListInMap)
      this.showFullListInMap = false;
  }

  public onFullScreenExit() {
    this.showAllLayoutMapEvent.emit();
  }

  public onFullScreen() {
    this.showFullMapEvent.emit();
    this.showFullListInMap = false;
  }

  public onDisplayRealEstateMark(event: any) {
    this.eventBus.emit({
      name: EVENT_BUS_EVENTS.REAL_ESTATE_MARK,
      value: event?.cKey,
    });
  }
}
