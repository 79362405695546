<div class="d-flex align-items-center gap-1">
  <div
    ngbDropdown
    [formGroup]="formRange"
    [ngClass]="rangePrice ? 'col-8' : 'col-12'"
  >
    <button
      type="button"
      class="customize-range-button d-flex align-items-center justify-content-between w-100 bg-white"
      [disabled]="disabled"
      ngbDropdownToggle
    >
      <div class="w-100 overflow-hidden">
        <ng-container *ngIf="fromControl.value > 0 || toControl.value > 0">
          <span>
            <span class="pe-1">{{ fromControl.value }}</span>
            <span class="gray-text" *ngIf="unit">({{ unit }}) </span>
            <span>-</span>
            <span class="px-1">{{ toControl.value }}</span>
            <span class="gray-text" *ngIf="unit">({{ unit }})</span>
          </span>
        </ng-container>

        <span
          class="gray-text m-auto title"
          *ngIf="!fromControl.value && !toControl.value"
        >
          {{ title }}
        </span>
      </div>
    </button>
    <div ngbDropdownMenu [class.dropdown-menu-relative]="dropdownRelative">
      <div class="content-container">
        <div class="content bg-white p-3">
          <div class="d-flex">
            <div>
              <div class="form-group">
                <label>Từ</label>
                <div class="d-flex align-items-center gap-1">
                  <input
                    autofocus
                    type="number"
                    matInput
                    placeholder="Từ"
                    formControlName="startTo"
                    #rangeARef
                    class="form-control ps-2"
                  />
                  <span matSuffix>{{ unit }}</span>
                  <div class="custom-divider"></div>
                </div>
              </div>
            </div>

            <div>
              <div class="form-group">
                <label>Đến</label>
                <div class="d-flex align-items-center gap-1">
                  <input
                    type="number"
                    placeholder="Đến"
                    matInput
                    formControlName="fromTo"
                    class="form-control ps-2"
                  />
                  <span matSuffix>{{ unit }}</span>
                </div>
                <mat-error
                  *ngIf="
                    getControl('fromTo').hasError('invalidNumber') &&
                    getControl('fromTo').touched
                  "
                  >Giá trị không hợp lệ</mat-error
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="btn-reset my-0 mx-auto py-3 px-0 d-flex justify-content-center"
        >
          <button
            (click)="handleReset()"
            mat-flat-button
            class="btn btn-danger p-0 my-0 mx-2"
            *ngIf="
              getControl('startTo').value > 0 || getControl('fromTo').value
            "
          >
            Đặt lại
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="rangePrice">
    <ng-select
      [items]="optionsPrice"
      [clearable]="false"
      bindLabel="name"
      bindValue="value"
      class="select-unit"
      [readonly]="disabled"
      [formControl]="unitForm"
    >
    </ng-select>
  </ng-container>
</div>
