<!-- Captions -->
<table
  class="table table-striped table-sm caption-top table-nowrap table-bordered text-muted"
>
  <thead class="table-light">
    <tr>
      <ng-container *ngTemplateOutlet="headers"></ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let d of data" scope="row">
      <ng-container
        *ngTemplateOutlet="rows; context: { $implicit: d }"
      ></ng-container>
    </tr>
  </tbody>
</table>
