import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { of } from "rxjs";
import { CopyRealEstateComponent } from "src/app/pages/real-estate-management/components/copy-real-estate/copy-real-estate.component";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";
import { DetectVideoFile } from "src/app/public/helpers/detect-media-type";
import { RealEstateModel } from "src/app/public/models/real-estate.model";
import { ImgSizeThumbnailPipe } from "src/app/public/pipes/image-size-thumbnail.pipe";
import { RealEstateMarkService } from "src/app/public/services/real-estate/real-estate-mark.service";

@Component({
  selector: "app-card-real-estate",
  templateUrl: "./card-real-estate.component.html",
  styleUrls: ["./card-real-estate.component.scss"],
})
export class CardRealEstateComponent implements OnInit {
  @Input() realEstate: RealEstateModel;
  @Input() fullWidthPage: boolean = true;
  @Input() hiddenFooter: boolean;
  @Output() onSelectedRealEstate = new EventEmitter();

  public LAND_PURPOSE = LAND_PURPOSE;

  public mediaUrl: string;
  public isVideo: boolean;

  constructor(
    public thumbnailPipe: ImgSizeThumbnailPipe,
    public modalService: NgbModal,
    private realEstateMarkService: RealEstateMarkService
  ) {}

  ngOnInit(): void {
    this.mediaUrl = this.thumbnailPipe.transform(
      this.realEstate.photos[0]?.url
    );
    this.isVideo = DetectVideoFile(this.realEstate.photos[0]?.url);
  }

  public onCopy(event: Event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(CopyRealEstateComponent, {
      size: "lg",
      centered: true,
      windowClass: "modal-copy-realestate-for-zalo",
    });
    modalRef.componentInstance.realEstate$ = of(this.realEstate);
  }

  public onClickAddToMark(event: Event) {
    event.stopPropagation();

    const currentValue = this.realEstate.id;
    this.realEstateMarkService.add(this.realEstate);
    this.realEstate.id = 0;

    setTimeout(() => {
      this.realEstate.id = currentValue;
    }, 0);
  }
}
