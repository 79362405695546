import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { GetClassPurposePipe } from "./get-class-purpose.pipe";
import { GetStatusColorPipe } from "./get-status-color.pipe";
import { GetStatusTextPipe } from "./get-status-text.pipe";
import { GetShortAddressPipe } from "src/app/public/pipes/get-short-address.pipe";
import { GetFullAddressPipe } from "src/app/public/pipes/get-full-address.pipe";
import { LandPurposePipe } from "src/app/public/pipes/land-purpose.pipe";
import { LandTypePipe } from "src/app/public/pipes/land-type.pipe";
import { TransformPriceToStringPipe } from "src/app/public/pipes/transform-price-to-string.pipe";
import { AddMeterSquareCharacterPipe } from "src/app/public/pipes/add-meter-square-character.pipe";
import { GetThumbnailImagePipe } from "src/app/public/pipes/get-thumbnail-image.pipe";
import { GetSmallImagePipe } from "src/app/public/pipes/get-small-image.pipe";
import { GetMediumImagePipe } from "src/app/public/pipes/get-medium-image.pipe";
import { GetOwnRealEstatePipe } from "./get-own-realestate.pipe";
import { GetAvatarImagePipe } from "./get-avatar-image.pipe";
import { GetShortStatusPipe } from "src/app/public/pipes/get-short-status.pipe";
import { GetShortStatusColorPipe } from "src/app/public/pipes/get-short-status-color.pipe";
import { NoCacheImgPipe } from "./no-cache-img.pipe";
import { InvisiblePhoneNumberPipe } from "./invisible-phone-number.pipe";
import { GetFullStatusPipe } from "./get-full-status.pipe";
import { ConvertToNumberPipe } from "./convert-to-number.pipe";
import { TruncateStringPipe } from "./truncate-string.pipe";
import { SafeUrlPipe } from "./safe-url.pipe";
import { TimeAgoPipe } from "./time-ago.pipe";
import { GetServiceColorPipe } from "./get-service-color.pipe";
import { ImgSizeThumbnailPipe } from "src/app/public/pipes/image-size-thumbnail.pipe";
import { RealEstateIconMakerPipe } from "./real-estate-icon-maker.pipe";
import { GetSystemColorPipe } from "./get-system-color.pipe";

@NgModule({
  declarations: [
    GetClassPurposePipe,
    GetStatusColorPipe,
    GetShortStatusColorPipe,
    GetStatusTextPipe,
    GetShortAddressPipe,
    GetFullAddressPipe,
    LandPurposePipe,
    LandTypePipe,
    TransformPriceToStringPipe,
    AddMeterSquareCharacterPipe,
    GetThumbnailImagePipe,
    GetSmallImagePipe,
    GetMediumImagePipe,
    GetOwnRealEstatePipe,
    GetAvatarImagePipe,
    GetShortStatusPipe,
    NoCacheImgPipe,
    InvisiblePhoneNumberPipe,
    GetFullStatusPipe,
    ConvertToNumberPipe,
    TruncateStringPipe,
    SafeUrlPipe,
    TimeAgoPipe,
    GetServiceColorPipe,
    ImgSizeThumbnailPipe,
    RealEstateIconMakerPipe,
    GetSystemColorPipe,
  ],
  exports: [
    GetClassPurposePipe,
    GetStatusColorPipe,
    GetStatusTextPipe,
    GetShortAddressPipe,
    GetFullAddressPipe,
    LandPurposePipe,
    LandTypePipe,
    TransformPriceToStringPipe,
    AddMeterSquareCharacterPipe,
    GetThumbnailImagePipe,
    GetSmallImagePipe,
    GetMediumImagePipe,
    GetOwnRealEstatePipe,
    GetAvatarImagePipe,
    GetShortStatusPipe,
    GetShortStatusColorPipe,
    NoCacheImgPipe,
    InvisiblePhoneNumberPipe,
    GetFullStatusPipe,
    ConvertToNumberPipe,
    TruncateStringPipe,
    SafeUrlPipe,
    TimeAgoPipe,
    GetServiceColorPipe,
    ImgSizeThumbnailPipe,
    RealEstateIconMakerPipe,
    GetSystemColorPipe,
  ],
  imports: [CommonModule],
  providers: [DatePipe],
})
export class PipesModule {}
