import {
  PAGE_DEFAULT,
  SIZE_PAGE_DEFAULT,
} from "./../constants/common.constant";
import { FormControl, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";
import { FormSearchRealEstate } from "../models/form-search-realestate.model";

@Injectable({
  providedIn: "root",
})
export class FormSearchRealEstateHelper {
  readonly #form: FormGroup;

  constructor() {
    this.#form = FormSearchRealEstateHelper.initForm();
  }

  get form() {
    return this.#form as FormGroup;
  }

  private static initForm(data?: FormSearchRealEstate): FormGroup {
    return new FormGroup({
      cenLat: new FormControl(data?.cenLat || null),
      cenLng: new FormControl(data?.cenLng || null),
      zoom: new FormControl(data?.zoom || null),
      types: new FormControl(data?.types || ""),
      search: new FormControl(data?.search || ""),
      purpose: new FormControl(data?.purpose || ""),
      fromPrice: new FormControl(data?.fromPrice || ""),
      toPrice: new FormControl(data?.toPrice || ""),
      area: new FormControl(data?.area || ""),
      frontWidth: new FormControl(data?.frontWidth || ""),
      frontWidthFrom: new FormControl(data?.frontWidthFrom || ""),
      frontWidthTo: new FormControl(data?.frontWidthTo || ""),
      acreageFrom: new FormControl(data?.acreageFrom || ""),
      acreageTo: new FormControl(data?.frontWidthTo || ""),
      bedRoom: new FormControl(data?.bedRoom || null),
      bathRoom: new FormControl(data?.bathRoom || null),
      page: new FormControl(PAGE_DEFAULT, { nonNullable: true }),
      limit: new FormControl(SIZE_PAGE_DEFAULT, { nonNullable: true }),
      status: new FormControl(data?.status || [""]),
      address: new FormControl(data?.address || ""),
      city: new FormControl(data?.city || ""),
      district: new FormControl(data?.district || ""),
      street: new FormControl(data?.street || ""),
      town: new FormControl(data?.town || ""),
      orderColumns: new FormControl("updated"),
      createdFrom: new FormControl(data?.createdFrom || null),
      createdTo: new FormControl(data?.createdTo || null),
      createdBy: new FormControl(data?.createdBy || null),
    });
  }
}
