<form [formGroup]="formRealEstate" class="mt-xl-0 mt-5">
  <div class="d-flex position-sticky top-0 form-header pb-2 z-3 bg-white">
    <div class="flex-grow-1">
      <h4>
        {{ product?.address | getFullAddress | async }}
      </h4>
      <div class="hstack gap-3 flex-wrap">
        <div class="w-md" style="width: 200px">
          <ng-select
            [readonly]="formRealEstate.controls['status']?.value !== '01'"
            [items]="
              formRealEstate.controls['status']?.value !== '01'
                ? REAL_ESTATE_STATUS
                : REAL_ESTATE_STATUS_SUMMARY
            "
            [readonly]="!isEdit && !!product.id"
            bindValue="cKey"
            [clearable]="false"
            formControlName="status"
            bindLabel="cValue"
            placeholder="Trạng thái BĐS"
          >
          </ng-select>
        </div>
        <div class="vr"></div>
        <div class="text-muted">
          ID :
          <span class="text-body fw-medium">{{ product.id }}</span>
        </div>
        <div class="vr"></div>
        <div class="text-muted">
          Ngày nhập :
          <span class="text-body fw-medium">{{
            product.createdAt | date: "dd-MM-yyy"
          }}</span>
        </div>
        <div class="vr"></div>
        <div class="text-muted">
          Cập nhật :
          <span class="text-body fw-medium">{{
            product.changedAt | date: "dd-MM-yyy"
          }}</span>
        </div>
        <div class="vr"></div>
        <div class="text-muted">
          CTV Đăng tin :
          <a href="tel:{{ product?.createdBy }}" class="text-body fw-medium">
            {{ product?.agencyName }} -
            {{ product?.createdBy | invisiblePhoneNumber | async }}</a
          >
        </div>

        <div class="vr"></div>
        <div class="text-muted">
          Giá/m2 :
          <span class="text-body fw-medium">
            {{
              product?.price
                | pricePerSquareMeter: product?.acreage
                | transformPriceToString
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex-shrink-0">
      <div>
        <button
          *ngIf="!isEdit"
          (click)="editEvent.emit()"
          class="btn btn-primary mx-2 align-middle"
          ngbTooltip="Bấm để sửa BĐS"
        >
          <i class="ri-pencil-fill align-bottom"></i> Sửa BĐS
        </button>
        <button
          *realEstateAllowToSell="product?.status"
          (click)="copyEvent.emit()"
          class="btn btn-primary"
          ngbTooltip="Bấm để Copy Chi tiết"
        >
          <i class="bx bx-copy"></i> Copy tới Zalo
        </button>

        <button
          *ngIf="isEdit"
          (click)="cancelChangeEvent.emit()"
          class="btn btn-danger mx-2 align-middle"
          type="submit"
        >
          <i class="bx bx-x-circle fs-14"></i> Huỷ chỉnh sửa
        </button>

        <button
          id="save-edit-real-estate"
          *ngIf="isEdit"
          (click)="saveEditEvent.emit()"
          class="btn btn-primary"
        >
          <i class="bx bx-save fs-14"></i> Lưu BĐS
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="mt-4">
      <app-form-owner-real-estate
        [formRealEstate]="formRealEstate"
        [realEstateId]="product.id"
        [isEdit]="isEdit"
      ></app-form-owner-real-estate>
    </div>

    <div class="mt-4">
      <app-form-real-estate-info
        [realEstateId]="product.id"
        [isEdit]="isEdit"
        [formRealEstate]="formRealEstate"
      ></app-form-real-estate-info>
    </div>

    <div class="mt-4">
      <app-form-detail-info
        [realEstateId]="product.id"
        [isEdit]="isEdit"
        [formRealEstate]="formRealEstate"
      ></app-form-detail-info>
    </div>

    <div class="mt-4">
      <app-form-internal-info
        [realEstateId]="product.id"
        [isEdit]="isEdit"
        [formRealEstate]="formRealEstate"
      ></app-form-internal-info>
    </div>

    <div class="mt-4">
      <app-form-other-info
        [isEdit]="isEdit"
        [formRealEstate]="formRealEstate"
        [realEstateId]="product.id"
      >
      </app-form-other-info>
    </div>
  </div>
</form>

<input
  #inputFileEdit
  type="file"
  multiple="false"
  accept=".jpg, .jpeg, .png"
  class="file-upload d-none"
/>
