import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "noCacheImg",
})
export class NoCacheImgPipe implements PipeTransform {
  transform(value: any): any {
    return value?.toString().concat(`?${Date.now()}`);
  }
}
