import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-filter-status",
  templateUrl: "./filter-status.component.html",
})
export class FilterStatusComponent implements OnInit {
  @Input() formChild: UntypedFormControl;
  @Input() status: {
    cKey: string;
    cValue: string;
    resources: string[];
    disabled?: boolean;
  }[];
  @Input() allowAllStatus: boolean = false;

  ngOnInit(): void {
    this.selectAllForDropdownItems(this.status);
    this.checkDisabledItem();
  }

  private selectAllForDropdownItems(items: any[]) {
    items.forEach((element: any) => {
      element["selectedAllGroup"] = "selectedAllGroup";
    });
  }

  private checkDisabledItem() {
    const status = this.formChild.getRawValue();
    if (!this.allowAllStatus) {
      this.status = this.status?.reduce((acc, s) => {
        s.disabled = !status?.includes(s.cKey);
        if (!s.disabled) acc.push(s);
        return acc;
      }, []);
    }
  }
}
