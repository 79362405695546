import { HLDirectivesModule } from "./../public/directives/directives.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";

// Swiper Slider

// Counter
import { CountToModule } from "angular-count-to";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlatpickrModule } from "angularx-flatpickr";
import { AgGridModule } from "ag-grid-angular";
import { PipesModule } from "src/app/public/pipes/pipes.module";
import { TableReastateComponent } from "./tables/realstate/table-reastate.component";
import { TableSuppliersComponent } from "./tables/suppliers/table-suppliers.component";
import { RouterModule } from "@angular/router";
import { UserService } from "../public/services/user/user.service";
import { SearchFilterBarComponent } from "./filter/search-filter-bar/search-filter-bar.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FilterPurposeComponent } from "./filter/search-filter-bar/filter-purpose/filter-purpose.component";
import { FilterRangeComponent } from "./filter/search-filter-bar/filter-range/filter-range.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { FilterTypeComponent } from "./filter/search-filter-bar/filter-type/filter-type.component";
import { FullSearchComponent } from "./filter/search-filter-bar/full-search/full-search.component";
import { ToastsContainerComponent } from "src/app/components/notifications/toasts-container.component";
import { TableComponent } from "./tables/table/table.component";
import { SearchFilterCustomerComponent } from "./filter/search-filter-customer/search-filter-customer.component";
import { FilterStatusComponent } from "./filter/search-filter-bar/filter-status/filter-status.component";
import { MapRealEstateComponent } from "./map-real-estate/map-real-estate.component";
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { QuickSearchModalComponent } from "src/app/components/quick-search-modal/quick-search-modal.component";
import { SearchSuggestionComponent } from "./search-suggestion/search-suggestion.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FilterPurposeMapComponent } from "./filter/search-filter-bar/filter-purpose-map/filter-purpose-map.component";
import { GetMediumImagePipe } from "../public/pipes/get-medium-image.pipe";
import { GetThumbnailImagePipe } from "../public/pipes/get-thumbnail-image.pipe";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MapOfRealEstateDetailComponent } from "./map-of-real-estate-detail/map-of-real-estate-detail.component";
import { RealEstateDetailOverviewComponent } from "./real-estate-detail-overview/real-estate-detail-overview.component";
import { FormCommonInfoComponent } from "../pages/real-estate-management/widgets/form-common-info/form-common-info.component";
import { FormOwnerRealEstateComponent } from "../pages/real-estate-management/widgets/form-owner-real-estate/form-owner-real-estate.component";
import { FormCarParkComponent } from "../pages/real-estate-management/widgets/form-car-park/form-car-park.component";
import { FormOtherInfoComponent } from "../pages/real-estate-management/widgets/form-other-info/form-other-info.component";
import { FormFurnitureComponent } from "../pages/real-estate-management/widgets/form-furniture/form-furniture.component";
import { FormExteriorComponent } from "../pages/real-estate-management/widgets/form-exterior/form-exterior.component";
import { FormSizeDetailComponent } from "../pages/real-estate-management/widgets/form-size-detail/form-size-detail.component";
import { SliderImageDetailComponent } from "../pages/real-estate-management/widgets/slider-image-detail/slider-image-detail.component";
import { FormRealEstateInfoComponent } from "../pages/real-estate-management/widgets/form-real-estate-info/form-real-estate-info.component";
import { MapSelectLocationComponent } from "../pages/real-estate-management/widgets/map-select-location/map-select-location.component";
import { CircleLoadingComponent } from "./animation/circle-loading/circle-loading.component";
import { SkeletonTableComponent } from "src/app/components/animation/loadingSkeleton/skeleton-table.component";
import { SearchFilterAgentComponent } from "./filter/search-filter-agent/search-filter-agent.component";
import { SendEmailModalComponent } from "./send-email-modal/send-email-modal.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SearchFilterNotificationComponent } from "./filter/search-filter-notification/search-filter-notification.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AgencyNotificationComponent } from "./send-notification-modal/agency-notification/agency-notification.component";
import { RealEstateDetailTabsComponent } from "./real-estate-detail-tabs/real-estate-detail-tabs.component";
import { SendNotificationModalComponent } from "./send-notification-modal/send-notification-modal.component";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { AreaNotificationComponent } from "./send-notification-modal/area-notification/area-notification.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SwiperModule } from "swiper/angular";
import { CopyRealEstateComponent } from "../pages/real-estate-management/components/copy-real-estate/copy-real-estate.component";
import { CardRealEstateComponent } from "./card-real-estate/card-real-estate.component";
import { ImgSizeThumbnailPipe } from "../public/pipes/image-size-thumbnail.pipe";
import { SearchFilterMapComponent } from "./filter/search-filter-map/search-filter-map.component";
import { SearchFilterHistorySubscribeComponent } from "./filter/search-filter-history-subscribe/search-filter-history-subscribe.component";
import { FilterDateRangeComponent } from "./filter/search-filter-bar/filter-date-range/filter-date-range.component";
import { FilterCreatorComponent } from "./filter/search-filter-bar/filter-creator/filter-creator.component";
import { FilterInfoRealEstateComponent } from "./filter/search-filter-bar/full-search/filter-info-real-estate/filter-info-real-estate.component";
import { SearchFilterEmployeeComponent } from "./filter/search-filter-employee/search-filter-employee.component";
import { SearchFilterRealEstateAuditComponent } from "./filter/search-filter-real-estate-audit/search-filter-real-estate-audit.component";

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    TableReastateComponent,
    TableSuppliersComponent,
    SearchFilterBarComponent,
    FilterPurposeComponent,
    FilterPurposeMapComponent,
    FilterRangeComponent,
    FilterTypeComponent,
    FullSearchComponent,
    SkeletonTableComponent,
    ToastsContainerComponent,
    TableComponent,
    SearchFilterCustomerComponent,
    SearchFilterAgentComponent,
    FilterStatusComponent,
    MapRealEstateComponent,
    QuickSearchModalComponent,
    SearchSuggestionComponent,
    MapOfRealEstateDetailComponent,
    RealEstateDetailOverviewComponent,
    FormCommonInfoComponent,
    FormOwnerRealEstateComponent,
    FormCarParkComponent,
    FormOtherInfoComponent,
    FormFurnitureComponent,
    FormExteriorComponent,
    FormSizeDetailComponent,
    SliderImageDetailComponent,
    FormRealEstateInfoComponent,
    MapSelectLocationComponent,
    CircleLoadingComponent,
    SendEmailModalComponent,
    RealEstateDetailTabsComponent,
    NotificationsComponent,
    SearchFilterNotificationComponent,
    SendNotificationModalComponent,
    AgencyNotificationComponent,
    AreaNotificationComponent,
    CopyRealEstateComponent,
    CardRealEstateComponent,
    SearchFilterMapComponent,
    SearchFilterHistorySubscribeComponent,
    FilterDateRangeComponent,
    FilterCreatorComponent,
    FilterInfoRealEstateComponent,
    SearchFilterEmployeeComponent,
    SearchFilterRealEstateAuditComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    SwiperModule,
    CountToModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    FlatpickrModule,
    ReactiveFormsModule,
    AgGridModule,
    PipesModule,
    NgbTooltipModule,
    NgbPopoverModule,
    HLDirectivesModule,
    NgSelectModule,
    OverlayModule,
    MatInputModule,
    NgbToastModule,
    NgbCollapseModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CKEditorModule,
  ],
  exports: [
    BreadcrumbsComponent,
    TableReastateComponent,
    TableSuppliersComponent,
    SearchFilterBarComponent,
    ToastsContainerComponent,
    SkeletonTableComponent,
    TableComponent,
    SearchFilterCustomerComponent,
    MapRealEstateComponent,
    QuickSearchModalComponent,
    SearchSuggestionComponent,
    FormCommonInfoComponent,
    FormOwnerRealEstateComponent,
    FormCarParkComponent,
    FormOtherInfoComponent,
    FormFurnitureComponent,
    SearchFilterAgentComponent,
    FormExteriorComponent,
    FormSizeDetailComponent,
    SliderImageDetailComponent,
    FormRealEstateInfoComponent,
    MapSelectLocationComponent,
    CircleLoadingComponent,
    AgmCoreModule,
    SendEmailModalComponent,
    SearchFilterNotificationComponent,
    SendNotificationModalComponent,
    AgencyNotificationComponent,
    AreaNotificationComponent,
    CopyRealEstateComponent,
    CardRealEstateComponent,
    SearchFilterMapComponent,
    FilterRangeComponent,
    FilterPurposeComponent,
    FilterTypeComponent,
    FilterStatusComponent,
    SearchFilterHistorySubscribeComponent,
    FilterDateRangeComponent,
    FilterCreatorComponent,
    FilterInfoRealEstateComponent,
    SearchFilterEmployeeComponent,
    SearchFilterRealEstateAuditComponent,
  ],
  providers: [
    UserService,
    GetThumbnailImagePipe,
    ImgSizeThumbnailPipe,
    GetMediumImagePipe,
    {
      provide: MAT_DATE_LOCALE,
      useValue: "vi-VN",
    },
  ],
})
export class HlSharedModule {}
