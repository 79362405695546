import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FullSearchComponent } from "./full-search/full-search.component";
import { Subject } from "rxjs";
import { AddBulkRealEstateModalComponent } from "src/app/pages/real-estate-management/components/add-bulk-real-estate-modal/add-bulk-real-estate-modal.component";
import {
  convertFileXlsxToJsonUtil,
  DEFINED_COLUMN_XLSX,
} from "src/app/public/utils/convert-xlsx-to-json.util";
import { FormSearchRealEstateHelper } from "src/app/public/helpers/form-search-real-estate.helper";
import { ActivatedRoute, Params } from "@angular/router";
import { take } from "rxjs/operators";
import {
  REAL_ESTATE_URL,
  STATUS_IN_PROGRESS,
  STATUS_QUICK_UPLOAD,
  STATUS_WAITING_APPROVE,
} from "src/app/public/constants/real-estate.constant";
import {
  PAGE_DEFAULT,
  SIZE_PAGE_DEFAULT,
} from "src/app/public/constants/common.constant";
import {
  PERMISSION_ACTIONS,
  REAL_ESTATE_RESOURCES,
} from "src/app/public/constants/permission.contants";

@Component({
  selector: "app-search-filter-bar",
  templateUrl: "./search-filter-bar.component.html",
  styleUrls: ["./search-filter-bar.component.scss"],
})
export class SearchFilterBarComponent implements OnInit, OnDestroy {
  public subscription$: Subject<any> = new Subject();
  public currentParam: string;
  public STATUS_QUICK_UPLOAD = STATUS_QUICK_UPLOAD;
  public STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;
  public STATUS_WAITING_APPROVED = STATUS_WAITING_APPROVE;
  public PERMISSION_ACTIONS = PERMISSION_ACTIONS;
  public REAL_ESTATE_RESOURCES = REAL_ESTATE_RESOURCES;
  public REAL_ESTATE_URL = REAL_ESTATE_URL;

  @ViewChild("inputFileXlsx") fileXlsx: any;
  @Input() searchLandParams: UntypedFormGroup;
  @Input() titleForParams: string;

  constructor(
    private modalService: NgbModal,
    private formService: FormSearchRealEstateHelper,
    private route: ActivatedRoute
  ) {
    this.searchLandParams = this.formService.form;
  }

  ngOnDestroy(): void {
    this.subscription$.next(null);
    this.subscription$.complete();
  }

  ngOnInit() {
    this.route.url.subscribe((params) => {
      this.currentParam = params[0].path;
    });
    // sync data form url.
    this.updateFormFromUrl();
  }

  public getControl(name: string) {
    return this.searchLandParams.get(name) as UntypedFormControl;
  }

  public updateFormFromUrl(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      const keys = Object.keys(params);
      if (keys?.length) {
        keys.forEach((k) => {
          this.searchLandParams.patchValue(
            {
              [k]: params[k],
            },
            {
              emitEvent: false,
            }
          );
        });
      }
    });
  }

  // SỰ KIỆN MỞ MODAL
  public onShowFullSearchModal() {
    const modalRef = this.modalService.open(FullSearchComponent, {
      size: "xl",
      centered: true,
    });

    modalRef.componentInstance.currentUrl = this.currentParam;
    modalRef.componentInstance.onSearchEvent.subscribe((params: any) => {
      this.searchLandParams.patchValue(params);
    });
  }

  public onEventFileSelected($event: any): void {
    const file = $event.target.files[0];
    const data = convertFileXlsxToJsonUtil(file);
    data.subscribe((data) => {
      const sheetJson = data[Object.keys(data)[0]];
      const modal = this.modalService.open(AddBulkRealEstateModalComponent, {
        fullscreen: "xl",
      });
      modal.componentInstance.data = this.convertData(sheetJson);
      modal.componentInstance.listData = this.convertData(sheetJson);
      modal.closed.subscribe((_) => {
        this.fileXlsx.nativeElement.value = "";
      });
    });
  }

  private convertData(data: any) {
    const columns = DEFINED_COLUMN_XLSX;
    return data.map((d: any) => {
      // @ts-ignore
      let newObject: any = {};
      // @ts-ignore
      Object.keys(d).map((k) => {
        const objectExist = columns.find(
          (column) => column.name.toUpperCase() === k.toUpperCase()
        );

        if (objectExist) {
          newObject[objectExist.key] = d[k];
        }
      });
      return newObject;
    });
  }

  public patchDefaultStatus(status: string[]) {
    this.searchLandParams.patchValue({
      status: status,
      page: PAGE_DEFAULT,
      limit: SIZE_PAGE_DEFAULT,
      street: "",
      address: "",
    });
  }

  // SỰ KIỆN CLEAR FORM SEARCH
  public clearFormRealEstate() {
    this.searchLandParams.reset();

    switch (this.currentParam) {
      case REAL_ESTATE_URL.QUICK_UPLOAD:
        this.patchDefaultStatus(this.STATUS_QUICK_UPLOAD);
        break;
      case REAL_ESTATE_URL.IN_PROGRESS:
        this.patchDefaultStatus(this.STATUS_IN_PROGRESS);
        break;
      case REAL_ESTATE_URL.WAITING_APPROVE:
        this.patchDefaultStatus(this.STATUS_WAITING_APPROVED);
        break;
      default:
        break;
    }
  }
}
