import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-form-size-detail",
  templateUrl: "./form-size-detail.component.html",
  styleUrls: ["./form-size-detail.component.scss"],
})
export class FormSizeDetailComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() isEdit: boolean;
}
