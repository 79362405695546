import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  PAGE_DEFAULT,
  SIZE_GET_ALL_DEFAULT,
  TABS_TITLE,
} from "src/app/public/constants/common.constant";
import { FormSearchRealEstateHelper } from "src/app/public/helpers/form-search-real-estate.helper";
import { EmployeeModel } from "src/app/public/models/employee.model";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "src/app/public/services/common/event-bus.service";
import { UserService } from "src/app/public/services/user/user.service";

@Component({
  selector: "app-full-search",
  templateUrl: "./full-search.component.html",
  styleUrls: ["./full-search.component.scss"],
})
export class FullSearchComponent implements OnInit {
  @Output() onSearchEvent = new EventEmitter();

  public TABS_TITLE = TABS_TITLE;
  public employeeList: EmployeeModel[] = [];

  public isCollapsed = Array(this.TABS_TITLE.length).fill(false);
  public searchLandParams: FormGroup = this.formSearchHelper.form;
  public unitForm = new FormControl("Triệu");

  public unitPrice = this.unitForm.value;
  public createdDefault: { from: string; to: string };
  public siteHandoverTimeDefault: { from: string; to: string };
  public openTimeDefault: { from: string; to: string };

  constructor(
    private modalService: NgbActiveModal,
    private route: ActivatedRoute,
    private userService: UserService,
    private eventBus: EventBusService,
    private formSearchHelper: FormSearchRealEstateHelper
  ) {}

  ngOnInit(): void {
    this.getAllEmployee();
    this.patchParams();
    this.observeSelectPriceChange();
    this.notAllowPatchEmptyValue();
  }

  public getControl(name: string) {
    return this.searchLandParams.controls[name] as FormControl;
  }

  private notAllowPatchEmptyValue() {
    this.searchLandParams.valueChanges.subscribe(({ createdBy }) => {
      if (!createdBy?.length) {
        this.getControl("createdBy").setValue(null, { emitEvent: false });
      }
    });
  }

  private observeSelectPriceChange() {
    this.unitForm.valueChanges.subscribe((newValue) => {
      this.unitPrice = newValue;
    });
  }

  private getAllEmployee(page?: number) {
    const currentPage = page ? page : PAGE_DEFAULT;

    this.userService
      .getListEmployee({
        page: currentPage,
        limit: SIZE_GET_ALL_DEFAULT,
      })
      .pipe()
      .subscribe((res: any) => {
        this.employeeList = this.employeeList.concat(...res.data);
        if (this.employeeList.length < res.totalItems) {
          this.getAllEmployee(currentPage + 1);
        }
      });
  }

  private patchParams() {
    this.route.queryParams.subscribe((params: any) => {
      const {
        status,
        types,
        purpose,
        fromPrice,
        toPrice,
        groupCustomer,
        origin,
        district,
        town,
        ..._params
      } = params;

      this.searchLandParams.patchValue({
        ..._params,
        status: this.convertStringToArray(status),
        types: this.convertStringToArray(types),
        purpose: this.convertStringToArray(purpose),
        fromPrice: this.convertPriceUnitReverse(fromPrice),
        toPrice: this.convertPriceUnitReverse(toPrice),
        district: this.convertStringToArray(district),
        town: this.convertStringToArray(town),
        negotiate: params?.negotiate ? JSON.parse(params.negotiate) : null,
        groupCustomer: Number(groupCustomer) || null,
        origin: Number(origin) || null,
      });

      this.getDefaultDate(params);
    });
  }

  private getDefaultDate(params: any) {
    this.createdDefault = {
      from: params.createdFrom,
      to: params.createdTo,
    };

    this.siteHandoverTimeDefault = {
      from: params.siteHandoverTimeFrom,
      to: params.siteHandoverTimeTo,
    };

    this.openTimeDefault = {
      from: params.openingTimeFrom,
      to: params.openingTimeTo,
    };
  }

  private convertStringToArray(value: string) {
    return value?.split(",").filter((item: string) => item.trim() !== "");
  }

  public onCloseModal() {
    this.modalService.close();
  }

  public onSearchEventEmit(searchForm: FormGroup) {
    if (searchForm.valid) {
      const { fromPrice, toPrice, ...paramValues } = searchForm.value;
      const searchLandParams = {
        ...paramValues,
        fromPrice: fromPrice ? this.convertPriceUnit(fromPrice) : null,
        toPrice: toPrice ? this.convertPriceUnit(toPrice) : null,
      };

      this.onSearchEvent.emit(searchLandParams);

      this.onCloseModal();
    }
  }

  public onResetField() {
    this.searchLandParams.reset();
    this.eventBus.emit({
      name: EVENT_BUS_EVENTS.RESET_ADVANCED_FILTER,
      value: true,
    });
  }

  private convertPriceUnit(price: any): number {
    switch (this.unitPrice) {
      case "Triệu":
        return price * 1000000;
      case "Tỷ":
        return price * 1000000000;
      default:
        return price;
    }
  }

  private convertPriceUnitReverse(price: any): number {
    switch (true) {
      case price <= 999999999: {
        this.unitForm.setValue("Triệu");
        return price / 1000000;
      }
      case price >= 999999999: {
        this.unitForm.setValue("Tỷ");
        return price / 1000000000;
      }
      default:
        return price;
    }
  }
}
