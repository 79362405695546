import { MenuItem } from "src/app/public/layouts/sidebar/menu.model";
import {
  AGENT_RESOURCES,
  CONFIGURATION_RESOURCES,
  CUSTOMER_RESOURCES,
  ORGANIZATION_RESOURCES,
  REAL_ESTATE_RESOURCES,
  SERVICE_URL,
} from "src/app/public/constants/permission.contants";

export const MENU: MenuItem[] = [
  // DASHBOARD
  {
    id: 1,
    label: "DASHBOARD",
    isTitle: true,
    service: "*",
  },
  {
    id: 2,
    label: "Dashboards",
    icon: "ri-dashboard-2-line",
    service: "*",
    subItems: [
      {
        id: 3,
        label: "MENUITEMS.DASHBOARD.LIST.HOME",
        link: "/dashboard/home",
        parentId: 2,
        resource: "*",
      },
    ],
  },

  // BĐS
  {
    id: 17,
    label: "Quản lý BĐS",
    isTitle: true,
    service: SERVICE_URL.API_LAND,
  },
  {
    id: 18,
    label: "Quản lý BĐS",
    icon: "ri-community-line",
    service: SERVICE_URL.API_LAND,
    subItems: [
      {
        id: 19,
        label: "BĐS chờ duyệt",
        link: "/real-estate-management/waiting-approve",
        resource: REAL_ESTATE_RESOURCES.WAITING_APPROVE,
      },
      {
        id: 20,
        label: "BĐS đang xử lý",
        link: "/real-estate-management/in-progress",
        resource: REAL_ESTATE_RESOURCES.IN_PROGRESS,
      },
      {
        id: 21,
        label: "BĐS đăng nhanh",
        link: "/real-estate-management/quick-upload",
        resource: REAL_ESTATE_RESOURCES.QUICK_UPLOAD,
      },
      {
        id: 22,
        label: "Bản đồ",
        link: "/real-estate-management/map-management",
        resource: REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
      },
    ],
  },

  // USER
  {
    id: 3,
    label: "Quản lý CTV",
    isTitle: true,
    service: SERVICE_URL.API_USER,
  },
  {
    id: 4,
    label: "Cộng tác viên (CTV)",
    icon: "las la-city",
    service: SERVICE_URL.API_USER,
    subItems: [
      {
        id: 5,
        label: "Đang hoạt động",
        link: "/user-management/agent/active",
        resource: AGENT_RESOURCES.ACTIVE,
      },
      // {
      //   id: 6,
      //   label: "Chờ duyệt",
      //   link: "/user-management/agent/waiting-approve",
      //   resource: "agent/waiting-approve",
      // },
      // {
      //   id: 7,
      //   label: "Từ chối",
      //   link: "/user-management/agent/reject",
      //   resource: "agent/reject",
      // },
    ],
  },
  // CRM
  {
    id: 12,
    label: "CRM",
    isTitle: true,
    service: SERVICE_URL.API_CRM,
  },
  {
    id: 13,
    label: "Khách hàng",
    icon: "ri-customer-service-fill",
    service: SERVICE_URL.API_CRM,
    subItems: [
      {
        id: 24,
        label: "DS khách hàng",
        parentId: 13,
        resource: CUSTOMER_RESOURCES.ACTIVE,
        subItems: [
          {
            id: 14,
            label: "Đang hoạt động",
            link: "/user-management/customer/active",
            resource: CUSTOMER_RESOURCES.ACTIVE,
          },
          {
            id: 15,
            label: "Chờ duyệt",
            link: "/user-management/customer/waiting-approve",
            resource: CUSTOMER_RESOURCES.WAITING_APPROVE,
          },
          {
            id: 16,
            label: "Từ chối",
            parentId: 13,
            link: "/user-management/customer/rejected",
            resource: CUSTOMER_RESOURCES.REJECTED,
          },
        ],
      },

      {
        id: 17,
        label: "Tasks",
        parentId: 13,
        link: "/user-management/tasks",
        resource: CUSTOMER_RESOURCES.REJECTED,
      },
      {
        id: 24,
        label: "Cài đặt",
        parentId: 13,
        resource: CUSTOMER_RESOURCES.REJECTED,
        subItems: [
          {
            id: 25,
            label: "Lịch trình",
            link: "setting-manangement/send-notify",
          },
          {
            id: 329,
            label: "DS duyệt nhận email",
            link: "setting-manangement/email-verification",
          },
        ],
      },
      {
        id: 311,
        label: "Tin nhắn",
        icon: "ri-notification-3-fill",
        resource: CUSTOMER_RESOURCES.NOTIFICATIONS,
        subItems: [
          {
            id: 312,
            label: "MENUITEMS.MESSAGE_MANAGEMENT.LIST.LIST_NOTIFICATION",
            link: "/message-management/notifications",
          },
          {
            id: 313,
            label: "Mailbox",
            link: "/message-management/mailbox",
          },
        ],
      },
    ],
  },

  // SETTINGS
  {
    id: 23,
    label: "Thiết lập & Quản lý nội bộ",
    isTitle: true,
    service: SERVICE_URL.API_USER,
  },
  {
    id: 15,
    label: "Nội bộ",
    icon: "ri-briefcase-fill",
    service: SERVICE_URL.API_USER,
    subItems: [
      {
        id: 5,
        label: "Phòng ban",
        link: "/user-management/organization/division",
        resource: ORGANIZATION_RESOURCES.DIVISION,
      },
      {
        id: 6,
        label: "Nhóm",
        link: "/user-management/organization/group",
        resource: ORGANIZATION_RESOURCES.GROUP,
      },
      {
        id: 9,
        label: "Nhân viên",
        link: "user-management/organization/employee",
        resource: ORGANIZATION_RESOURCES.EMPLOYEE,
      },
    ],
  },
  {
    id: 316,
    label: "Thiết lập quyền",
    icon: "ri-account-circle-line",
    service: SERVICE_URL.API_USER,
    subItems: [
      {
        id: 317,
        label: "Admin System",
        icon: "ri-account-circle-line",
        resource: CONFIGURATION_RESOURCES.SERVICES,
        subItems: [
          {
            id: 318,
            label: "Base Actions",
            link: "configuration/actions",
            resource: CONFIGURATION_RESOURCES.ACTIONS,
          },
          {
            id: 319,
            label: "Base Services",
            link: "configuration/services",
            resource: CONFIGURATION_RESOURCES.SERVICES,
          },
          {
            id: 320,
            label: "Resources-Action",
            link: "configuration/resources",
            resource: CONFIGURATION_RESOURCES.RESOURCES,
          },
          {
            id: 321,
            label: "Quyền - Hành động",
            link: "configuration/policies",
            resource: CONFIGURATION_RESOURCES.POLICIES,
          },
          {
            id: 322,
            label: "Nhóm quyền",
            link: "configuration/roles",
            resource: CONFIGURATION_RESOURCES.ROLES,
          },
        ],
      },
      {
        id: 323,
        label: "App Agency",
        icon: "ri-account-circle-line",
        resource: CONFIGURATION_RESOURCES.SERVICES,
        subItems: [
          {
            id: 324,
            label: "Base Actions",
            link: "configuration/actions",
            resource: CONFIGURATION_RESOURCES.ACTIONS,
          },
          {
            id: 325,
            label: "Base Services",
            link: "configuration/services",
            resource: CONFIGURATION_RESOURCES.SERVICES,
          },
          {
            id: 326,
            label: "Resources-Action",
            link: "configuration/resources",
            resource: CONFIGURATION_RESOURCES.RESOURCES,
          },
          {
            id: 327,
            label: "Quyền - Hành động",
            link: "configuration/policies",
            resource: CONFIGURATION_RESOURCES.POLICIES,
          },
          {
            id: 328,
            label: "Nhóm quyền",
            link: "configuration/roles",
            resource: CONFIGURATION_RESOURCES.ROLES,
          },
        ],
      },
    ],
  },
];
