import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild, ViewEncapsulation
} from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";

@Component({
  selector: "app-file-image-uploads",
  templateUrl: "./file-image-uploads.component.html",
  styleUrls: ["./file-image-uploads.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

/**
 * FileUploads Component
 */
export class FileImageUploadsComponent implements AfterViewInit {
  files: any[] = [];
  linkFiles: String[] | any = [];
  CheckStyleUrl = CheckStyleUrl;

  @ViewChild("btnSubmitUpload") btnSubmitUpload: ElementRef;
  submit$ = new Subject();

  @Input() isMultiFile: boolean = false;

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngAfterViewInit(): void {
    // Listen button submit and fire event `submit$`
    this.listenEventSubmitUpload();
    // Listen event `submit$` and upload images
  }

  listenEventSubmitUpload(): void {
    const submitUpload$ = fromEvent(
      this.btnSubmitUpload.nativeElement,
      "click"
    );
    submitUpload$.pipe().subscribe((_) => this.submit$.next(true));
  }

  onFileSelected(event: any): void {
    const files = event.target.files;
    if (files.length) {
      Array.from(files).forEach((file: any) => {
        this.files.unshift(file);
      });
    }
  }

  onDeleteFile(index: number): void {
    this.files.splice(index, 1);
    this.linkFiles.splice(index, 1);
  }

  approvedSubmit(): void {
    this.activeModal.close(this.files);
  }
}
