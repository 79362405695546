import { map, reduce, tap } from "rxjs/operators";
import { RealEstateModel } from "./../../models/real-estate.model";
import { BehaviorSubject, Observable } from "rxjs";
import { RealEsateHttpService } from "./real-esate-http.service";
import { Injectable } from "@angular/core";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";

@Injectable({
  providedIn: "root",
})
export class RealEstateService {
  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  constructor(private realEstateHttp: RealEsateHttpService) {}

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  /**
   * @function: Get List Real Estate based on Search, Sort & Filter.
   * @param
   */
  public getRealEstates(params: any): Observable<RealEstateModel[]> {
    return this.realEstateHttp.getRealEstates(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _pageSize = data?.data?.length;
        const _limit = params?.limit;

        this._page.next(_page);
        this._total.next(_total);
        this._pageSize.next(_pageSize);
        this._limit.next(_limit);
      })
    ) as Observable<RealEstateModel[]>;
  }

  public getRealEstatesV2(params: any): Observable<RealEstateModel[]> {
    return this.realEstateHttp.getRealEstatesV2(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _pageSize = data?.data?.length;
        const _limit = params?.limit;

        this._page.next(_page);
        this._total.next(_total);
        this._pageSize.next(_pageSize);
        this._limit.next(_limit);
      })
    ) as Observable<RealEstateModel[]>;
  }

  public getRealEstatePostGres(params: any): Observable<RealEstateModel[]> {
    return this.realEstateHttp.getRealEstatePostGres(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _pageSize = data?.data?.length;
        const _limit = params?.limit;

        this._page.next(_page);
        this._total.next(_total);
        this._pageSize.next(_pageSize);
        this._limit.next(_limit);
      })
    ) as Observable<RealEstateModel[]>;
  }

  /**
   * @function: Get List Real Estate based on Search, Sort & Filter.
   * @param
   */
  public getNameRealEstate(params: any): Observable<RealEstateModel[]> {
    return this.realEstateHttp.searchRealEstateName(params) as Observable<
      RealEstateModel[]
    >;
  }

  public getNewRealEstates(params: any): Observable<RealEstateModel[]> {
    return this.getRealEstates(params).pipe(
      map((res: any) => {
        const data = res?.data?.map((realEstate: RealEstateModel) => {
          const { address, photos } = realEstate;

          // check has location: latitude & longitude
          const hasLocation = Boolean(address?.latitude && address?.longitude);

          // check has video
          const hasVideo = photos?.find(({ url }) => {
            return CheckStyleUrl(url) === "video";
          });

          const avatar = photos?.find(({ url }) => {
            return CheckStyleUrl(url) === "image";
          })?.url;

          return { ...realEstate, hasLocation, hasVideo, avatar };
        });

        return { ...res, data };
      })
    ) as Observable<RealEstateModel[]>;
  }

  public getNewRealEstatesV2(params: any): Observable<RealEstateModel[]> {
    return this.getRealEstatesV2(params).pipe(
      map((res: any) => {
        const data = res?.data?.map((realEstate: RealEstateModel) => {
          const { address, photos } = realEstate;

          // check has location: latitude & longitude
          const hasLocation = Boolean(address?.latitude && address?.longitude);

          // check has video
          const hasVideo = photos?.find(({ url }) => {
            return CheckStyleUrl(url) === "video";
          });

          const avatar = photos?.find(({ url }) => {
            return CheckStyleUrl(url) === "image";
          })?.url;

          return { ...realEstate, hasLocation, hasVideo, avatar };
        });

        return { ...res, data };
      })
    ) as Observable<RealEstateModel[]>;
  }

  public updateRealEstate(payload: RealEstateModel): Observable<any> {
    return this.realEstateHttp.updateRealEstate(payload);
  }

  public updateRealEstateQuickUp(payload: RealEstateModel): Observable<any> {
    return this.realEstateHttp.updateRealEstateQuickUp(payload);
  }

  public updateStatusRealEstate(payload: any): Observable<any> {
    return this.realEstateHttp.approveRealEstate(payload);
  }

  public updateRejectRealEstate(payload: any): Observable<any> {
    return this.realEstateHttp.updateRejectRealEstate(payload);
  }

  public deletedRealEstateRaw(id: any): Observable<any> {
    return this.realEstateHttp.deleteRealEstateRaw(id);
  }

  public addRealEstate(payload: any): Observable<any> {
    return this.realEstateHttp.addRealEstate(payload);
  }

  /**
   * @function: Get info detail of Real-Estate
   * @param id of RealEstate want to get info detail
   * @returns
   */
  public getDetailRealEstate(id: string | number): Observable<RealEstateModel> {
    return this.realEstateHttp.getRealEstate(id).pipe(
      reduce((_: any, cur: any) => {
        return { ...cur?.item };
      }, {})
    );
  }

  /*****************************************************************
   ********** BẤT ĐỘNG SẢN CHƯA XỬ LÝ (REAL ESTATE RAW) ************
   *****************************************************************/
  public getRealEstatesRaw(params: any): Observable<RealEstateModel[]> {
    return this.realEstateHttp.getRealEstatesRaw(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _pageSize = data?.data?.length;
        const _limit = params?.limit;

        this._page.next(_page);
        this._total.next(_total);
        this._pageSize.next(_pageSize);
        this._limit.next(_limit);
      }),
      map((res) => {
        const data = res?.data?.map((realEstate: RealEstateModel) => {
          const { address, photos } = realEstate;

          // check has location: latitude & longitude
          const hasLocation = Boolean(address?.latitude && address?.longitude);

          // check has video
          const hasVideo = photos?.find(({ url }) => {
            return CheckStyleUrl(url) === "video";
          });

          const avatar = photos?.find(({ url }) => {
            return CheckStyleUrl(url) === "image";
          })?.url;

          return { ...realEstate, hasLocation, hasVideo, avatar };
        });
        return { ...res, data };
      })
    ) as Observable<RealEstateModel[]>;
  }

  public getDetailRealEstateRaw(
    id: string | number
  ): Observable<RealEstateModel> {
    return this.realEstateHttp.getRealEstateRaw(id).pipe(
      reduce((_: any, cur: any) => {
        return { ...cur?.item };
      }, {})
    );
  }

  public getRealEstateInPolygons(payload: any, params?: any): Observable<any> {
    return this.realEstateHttp.getRealEstateInPolygons(payload, params);
  }
}
