<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Bộ lọc và tìm kiếm</h5>
    <button
      type="button"
      class="close"
      (click)="onCloseModal()"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span>&times;</span>
    </button>
  </div>
  <hr />
  <div
    class="text-dark cursor-pointer"
    (click)="collapse.toggle()"
    [attr.aria-expanded]="isCollapsed"
    aria-controls="collapseExample"
  >
    <span class="badge badge-soft-primary fs-12"
      ><i
        [ngClass]="isCollapsed ? 'bx bx-caret-right' : 'bx bx-caret-down'"
        class="pe-1"
      ></i
      >Truy vấn bất động sản</span
    >
    <div class="border border-dashed"></div>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="modal-body customize-modal-content">
      <form
        (keydown.enter)="$event.preventDefault()"
        [formGroup]="searchLandParams"
      >
        <div class="d-flex flex-wrap justify-content-center">
          <!-------------------- ĐỊA CHỈ------------------------------>
          <div class="row col-sm-12 col-md-12 col-lg-12 flex-wrap">
            <div class="position-relative pt-2 col-sm-12 col-md-12 col-lg-8">
              <label class="form-lable">Địa chỉ</label>
              <app-search-suggestion
                [searchForm]="searchLandParams"
                [takeFistSuggestion]="true"
              >
              </app-search-suggestion>
            </div>

            <div class="search-box pt-2 col-sm-6 col-md-6 col-lg-4">
              <label class="form-lable">Trạng thái bất động sản</label>
              <app-filter-status
                [formChild]="getControl('status')"
                [status]="STATUS_APPROVED"
              ></app-filter-status>
            </div>

            <div class="search-box pt-2 col-lg-4 col-sm-6">
              <label class="filter-lable">Diện tích</label>
              <app-filter-range
                [fromControl]="getControl('acreageFrom')"
                [toControl]="getControl('acreageTo')"
                title="Diện tích"
                unit="㎡"
              ></app-filter-range>
            </div>

            <div class="search-box pt-2 col-lg-4 col-sm-6">
              <label class="filter-lable">Mặt tiền</label>
              <app-filter-range
                title="Mặt tiền"
                unit="m"
                [fromControl]="getControl('frontWidthFrom')"
                [toControl]="getControl('frontWidthTo')"
              ></app-filter-range>
            </div>

            <div class="search-box pt-2 col-lg-4 col-sm-6">
              <label class="filter-lable">Kiểu bất động sản</label>
              <app-filter-purpose-map
                [childControl]="getControl('purpose')"
              ></app-filter-purpose-map>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer pt-3">
  <button
    class="btn btn-danger"
    (click)="clearFormRealEstate()"
    ngbTooltip="Đặt lại"
  >
    <i class="mdi mdi-restore"></i> Đặt lại
  </button>
</div>
