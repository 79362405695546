<ng-container [formGroup]="formSearchAndFilter">
  <div class="row row-cols-5 pt-3">
    <div class="col mb-3">
      <label class="form-label">Tầng hầm</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Tầng hầm"
        formControlName="basement"
      ></ng-select>
    </div>
    <div class="col mb-3">
      <label class="form-label">Căn góc</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Căn góc"
        formControlName="corner"
      ></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Thang máy</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Thang máy"
      ></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Ảnh</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Ảnh"
        formControlName="hasImage"
      ></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Video</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Video"
        formControlName="hasVideo"
      ></ng-select>
    </div>
  </div>

  <div class="row row-cols-5">
    <div class="col mb-3">
      <label class="form-label">Toạ độ</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Chọn toạ độ"
        formControlName="hasLocation"
      ></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Lịch sử cho thuê</label>
      <ng-select placeholder="Lịch sử cho thuê"></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Nguồn</label>
      <ng-select
        [items]="ORIGIN_PROVIDE"
        bindLabel="label"
        bindValue="key"
        placeholder="Chọn nguồn BĐS"
        formControlName="origin"
      ></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Pháp lý</label>
      <ng-select
        [items]="jurdical$ | async"
        bindValue="cKey"
        bindLabel="cValue"
        placeholder="Pháp lý"
        formControlName="businessStatus"
      ></ng-select>
    </div>
  </div>
</ng-container>
