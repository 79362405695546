export interface UserReq {
  username: string;
  password: string;
}

export const APP_USER_STATUS = {
  ACTIVE: "01",
  INACTIVE: "02",
  PENDING_OTP: "03", // Đang chờ nhập OTP
  VERIFIED: "04", //
  CANCEL: "05", // ADmin không duyệt cho vào xem
  REVIEWING: "06", // chờ Admin duyệt sau khi đã nhập OTP thành công
};

export const APP_ACCOUNT_TYPE = {
  SUPER_ADMIN: "SP",
  ADMIN: "AD",
  PERSONAL: "PE",
  AGENT: "AG",
  BUSINESS: "BU",
};

export const CONTACT_SCHEDULE_TYPE = {
  KEY: "1",
  LABEL: "",
};

export const BOOKING_SCHEDULE_TYPE = {
  KEY: "2",
  LABEL: {
    VIDEO: "Qua video",
    LIVE: "Trực tiếp",
  },
};

export const USER_STATUS_FILTER = [
  {
    label: "Tất cả",
    key: null,
  },
  {
    label: "Hoạt động",
    key: APP_USER_STATUS.ACTIVE,
  },
  {
    label: "Không hoạt động",
    key: APP_USER_STATUS.INACTIVE,
  },
];

export const USER_TYPE_FILTER = [
  {
    label: "Cá nhân",
    key: APP_ACCOUNT_TYPE.PERSONAL,
  },
  {
    label: "Công ty",
    key: APP_ACCOUNT_TYPE.BUSINESS,
  },
];

export const USER_GENDER = [
  {
    id: "1",
    label: "Nam",
  },
  {
    id: "2",
    label: "Nữ",
  },
  {
    id: "3",
    label: "Khác",
  },
];

export const EMAIL_VERIFICATION_ACTIVE_FILTER = [
  {
    label: "Đã duyệt",
    key: "true",
  },
  {
    label: "Từ chối",
    key: "false",
  },
];

export const SUBSCRIBE_SEARCH_ACTIVE_FILTER = [
  {
    label: "Tất cả",
    key: "",
  },
  {
    label: "Đã duyệt",
    key: "true",
  },
  {
    label: "Từ chối",
    key: "false",
  },
];

export const SUBSCRIBE_SEARCH_CREATED_AT = [
  {
    label: "Mới nhất",
    key: "DESC",
  },
  {
    label: "Cũ nhất",
    key: "ASC",
  },
];
