import { Component, inject, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ACTIVE_STATUS_FILTER } from "src/app/public/constants/common.constant";
import { ORIGIN_PROVIDE } from "src/app/public/constants/real-estate.constant";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Component({
  selector: "app-filter-detail-info",
  templateUrl: "./filter-detail-info.component.html",
})
export class FilterDetailInfoComponent implements OnInit {
  private categoryService = inject(CategoryService);

  @Input() formSearchAndFilter: FormGroup;

  public jurdical$: Observable<any>;

  public ACTIVE_STATUS_FILTER = ACTIVE_STATUS_FILTER;
  public ORIGIN_PROVIDE = ORIGIN_PROVIDE;

  ngOnInit() {
    this.getAllJurdical();
  }

  private getAllJurdical() {
    this.jurdical$ = this.categoryService.getAllJuridical();
  }
}
