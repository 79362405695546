<div [formGroup]="formCommon" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Thông tin chung</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2">
    <div class="row my-2">
      <div class="col-md-4">
        <label class="form-label"></label>
      </div>
      <div class="col-md-8 d-flex gap-2 mt-2">
        <div class="form-check mb-2 form-radio-info">
          <input
            [attr.disabled]="isEdit ? null : true"
            class="form-check-input"
            type="radio"
            name="purpose"
            formControlName="purpose"
            [value]="'2'"
            style="opacity: 1"
            id="flexRadioDefault1"
          />
          <label
            class="form-check-label text-info"
            style="opacity: 1"
            for="flexRadioDefault1"
          >
            Cho Thuê
          </label>
        </div>

        <div class="form-check form-radio-warning">
          <input
            [attr.disabled]="isEdit ? null : true"
            class="form-check-input"
            type="radio"
            formControlName="purpose"
            name="purpose"
            style="opacity: 1"
            [value]="'1'"
            id="flexRadioDefault2"
          />
          <label
            class="form-check-label text-warning"
            for="flexRadioDefault2"
            style="opacity: 1"
          >
            Bán
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class="form-label"></label>
      </div>
      <div class="col-md-8 d-flex gap-2">
        <small
          *ngIf="formCommon.controls['purpose'].value === '0'"
          class="text-danger"
          >Vui lòng chọn loại thuê/ bán</small
        >
      </div>
    </div>

    <div class="row my-2">
      <div class="col-md-4">
        <label class="form-label">Loại hình BĐS</label>
      </div>

      <div class="col-md-8">
        <ng-select
          [readonly]="!isEdit"
          formControlName="type"
          [items]="typeOfRealEstate$ | async"
          bindValue="cKey"
          bindLabel="cValue"
          placeholder="Chọn loại hình BĐS..."
        ></ng-select>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Giá (VNĐ)</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            [attr.disabled]="isEdit ? null : true"
            type="name"
            formControlName="price"
            class="form-control"
            placeholder=""
          />
          <span class="input-group-text bg-soft-light text-muted prefix-width"
            >(VNĐ)</span
          >
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Diện tích xây dựng</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            [attr.disabled]="isEdit ? null : true"
            type="name"
            formControlName="acreage"
            class="form-control"
            placeholder=""
          />
          <span class="input-group-text bg-soft-light text-muted prefix-width"
            >(m2)</span
          >
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Số tầng</label>
      </div>
      <div class="col-md-8">
        <div
          class="input-step full-width"
          [ngClass]="{ 'group-disabled': !isEdit }"
        >
          <button
            *ngIf="isEdit"
            type="button"
            class="minus"
            (click)="decrement(formCommon.controls['floors'])"
          >
            –
          </button>
          <input
            [attr.disabled]="isEdit ? null : true"
            type="number"
            class="product-quantity"
            formControlName="floors"
            min="0"
            max="100"
          />
          <button
            *ngIf="isEdit"
            type="button"
            class="plus"
            (click)="increment(formCommon.controls['floors'])"
          >
            +
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Mặt tiền</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            [attr.readonly]="isEdit ? null : true"
            type="name"
            formControlName="frontWidth"
            class="form-control"
            placeholder=""
          />
          <span class="input-group-text bg-soft-light text-muted prefix-width"
            >(m)</span
          >
        </div>
      </div>
    </div>

    <div class="row mt-2 mb-3">
      <div class="col-md-4">
        <label class="form-label">Hướng nhà</label>
      </div>
      <div class="col-md-8">
        <ng-select
          [readonly]="!isEdit"
          [items]="redirectOfRealEstate$ | async"
          bindValue="cKey"
          bindLabel="cValue"
          formControlName="direction"
          placeholder="Chọn hướng nhà..."
        ></ng-select>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Đường rộng</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            [attr.disabled]="isEdit ? null : true"
            type="name"
            formControlName="streetWidth"
            class="form-control"
            placeholder=""
          />
          <span class="input-group-text bg-soft-light text-muted prefix-width"
            >(m)</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
