<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i> Nội thất</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2 row">
    <div class="col-md-3 pb-2">
      <label class="form-label">Tình trạng nội thất</label>
      <ng-select
        [clearable]="false"
        [readonly]="!isEdit"
        formControlName="furniture"
        [items]="statusFurniture$ | async"
        bindValue="cKey"
        bindLabel="cValue"
        placeholder="Chọn tình trạng nội thất..."
      ></ng-select>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Phòng ngủ</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['bedrooms'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          [ngClass]="{
            'is-valid': isEdit && !formRealEstate.controls['bedrooms']?.errors
          }"
          type="number"
          formControlName="bedrooms"
          min="0"
          placeholder="(sl)"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['bedrooms'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Phòng vệ sinh</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['toilets'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          type="number"
          class="product-quantity"
          formControlName="toilets"
          placeholder="(sl)"
          min="0"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['toilets'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Nóng Lạnh</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['heaters'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          type="number"
          class="product-quantity"
          formControlName="heaters"
          placeholder="(sl)"
          min="0"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['heaters'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Phòng bếp</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['kitchens'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          type="number"
          class="product-quantity"
          formControlName="kitchens"
          placeholder="(sl)"
          min="0"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['kitchens'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Phòng khách</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['livingRooms'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          type="number"
          class="product-quantity"
          formControlName="livingRooms"
          placeholder="(sl)"
          min="0"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['livingRooms'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Điều hoà</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['airConditioners'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          required
          type="number"
          formControlName="airConditioners"
          placeholder="(sl)"
          class="product-quantity"
          min="0"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['airConditioners'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Thang máy</label>
      <div
        class="input-step full-width"
        [ngClass]="{ 'group-disabled': !isEdit }"
      >
        <button
          *ngIf="isEdit"
          type="button"
          class="minus"
          (click)="decrement(formRealEstate.controls['elevators'])"
        >
          –
        </button>
        <input
          [attr.disabled]="isEdit ? null : true"
          type="number"
          class="product-quantity"
          placeholder="(sl)"
          formControlName="elevators"
          min="0"
          max="100"
        />
        <button
          *ngIf="isEdit"
          type="button"
          class="plus"
          (click)="increment(formRealEstate.controls['elevators'])"
        >
          +
        </button>
      </div>
    </div>

    <div class="col-md-3 pb-2">
      <label class="form-label">Chất liệu sàn</label>
      <ng-select
        [clearable]="false"
        [readonly]="!isEdit"
        formControlName="floorMaterial"
        [items]="floorMaterials$ | async"
        bindValue="cKey"
        bindLabel="cValue"
        placeholder="Chọn chất liệu sàn..."
      ></ng-select>
    </div>
  </div>
</div>
