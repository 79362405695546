import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { IncrementFormControl } from "src/app/public/utils/increment-form-control";
import { DecrementFormControl } from "src/app/public/utils/decrement-form-control";

@Component({
  selector: "app-form-common-info",
  templateUrl: "./form-common-info.component.html",
  styleUrls: ["./form-common-info.component.scss"],
})
export class FormCommonInfoComponent {
  decrement = DecrementFormControl;
  increment = IncrementFormControl;

  public typeOfRealEstate$: Observable<any>;
  public redirectOfRealEstate$: Observable<any>;

  @Input() formCommon: UntypedFormGroup;
  @Input() isEdit: boolean;

  constructor(public categoryService: CategoryService) {
    this.typeOfRealEstate$ = categoryService.getAllType();
    this.redirectOfRealEstate$ = categoryService.getAllDirection();
  }
}
