<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i> Khu vực đỗ xe ô-tô</span
    >
  </div>
  <div class="border border-dashed"></div>
  <div class="py-2 row">
    <div class="col-md-4 mt-2">
      <label class="form-label">Đặc điểm khu vực đỗ xe</label>
      <ng-select
        [readonly]="!isEdit"
        [items]="parkingType$ | async"
        [clearable]="false"
        bindValue="cKey"
        formControlName="parking"
        bindLabel="cValue"
        placeholder="Chọn đặc điểm khu đỗ xe"
      ></ng-select>
    </div>

    <div class="col-md-4 mt-2">
      <label class="form-label">Khoảng cách từ nhà tới bãi đỗ xe</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="parkingDistance"
          type=""
          class="form-control"
          placeholder="(m)"
        />
      </div>
    </div>
  </div>
</div>
