import { Directive, Input } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[tbCellDef]",
})
export class TbCellDefDirective {
  constructor() {}

  @Input() tbCellDef: string;
}
