<div>
  <ng-select
    [items]="items"
    [multiple]="true"
    bindLabel="title"
    bindValue="id"
    placeholder="Kiểu BĐS"
    [clearable]="true"
    [formControl]="childControl"
    [closeOnSelect]="false"
    [selectableGroup]="true"
    [selectableGroupAsModel]="false"
    groupBy="selectedAllGroup"
  >
    <ng-template ng-optgroup-tmp let-item$="item$">
      <div class="d-flex align-items-center gap-1">
        <input type="checkbox" [ngModel]="item$.selected" />
        Chọn tất cả
      </div>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index"
    >
      <div class="d-flex align-items-center">
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }"
          class="me-1"
        />
        <span class=""> {{ item["title"] }}</span>
      </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let i of items | slice: 0:1">
        <span class="ng-value-label"> {{ $any(i)?.title }}</span>
        <span class="ng-value-icon right" (click)="clear(i)" aria-hidden="true"
          >×</span
        >
      </div>
      <div class="ng-value" *ngIf="items.length > 1">
        <span class="ng-value-label"> + {{ items.length - 1 }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
