import { Component, Input, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED,
  REAL_ESTATE_URL,
} from "src/app/public/constants/real-estate.constant";
import { FilterDateRangeComponent } from "../../filter-date-range/filter-date-range.component";

@Component({
  selector: "app-filter-info-real-estate",
  templateUrl: "./filter-info-real-estate.component.html",
})
export class FilterInfoRealEstateComponent {
  @Input() formSearchAndFilter: FormGroup;
  @Input() currentUrl: string;
  @Input() unitPrice: string;
  @Input() unitForm: FormControl;
  @Input() rangeDateDefault: { createdFrom: string; createdTo: string };
  @ViewChild(FilterDateRangeComponent)
  filterDateRangeComponent: FilterDateRangeComponent;
  public formRange: UntypedFormGroup;

  public STATUS_APPROVED = REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED;
  public REAL_ESTATE_URL = REAL_ESTATE_URL;

  constructor() {}

  public getControl(name: string) {
    return this.formSearchAndFilter.controls[name] as UntypedFormControl;
  }

  public onFormRangeChange(form: UntypedFormGroup) {
    this.formRange = form;
  }

  public resetForm() {
    if (this.formRange) {
      this.formRange.reset();
    }
  }
}
