<div [style.opacity]="disabled ? '0.6' : null">
  <ng-select
    [items]="items"
    [multiple]="isMutipleChoose"
    bindLabel="title"
    bindValue="id"
    placeholder="Kiểu BĐS"
    [clearable]="true"
    [formControl]="childControl"
    [closeOnSelect]="!isMutipleChoose"
    [style.pointer-events]="disabled ? 'none' : null"
  >
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let i of items | slice: 0:1">
        <span class="ng-value-label"> {{ $any(i)?.title }}</span>
        <span class="ng-value-icon right" (click)="clear(i)">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 1">
        <span class="ng-value-label"> + {{ items.length - 1 }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
