<ng-container [formGroup]="formSearchAndFilter">
  <div class="row pt-3 row-cols-5">
    <div class="col mb-3">
      <label class="form-label">Mặt tiền</label>
      <app-filter-range
        title="Mặt tiền"
        unit="m"
        [fromControl]="getControl('frontWidthFrom')"
        [toControl]="getControl('frontWidthTo')"
      ></app-filter-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Diện tích cho thuê</label>
      <app-filter-range
        [fromControl]="getControl('acreageFrom')"
        [toControl]="getControl('acreageTo')"
        title="Diện tích cho thuê"
        unit="㎡"
      ></app-filter-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Diện tích đất</label>
      <app-filter-range
        [fromControl]="getControl('landAreaFrom')"
        [toControl]="getControl('landAreaTo')"
        title="Diện tích đất"
        unit="㎡"
      ></app-filter-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Diện tích xây dựng</label>
      <app-filter-range
        [fromControl]="getControl('acreageBuiltFrom')"
        [toControl]="getControl('acreageBuiltTo')"
        title="Diện tích xây dựng"
        unit="㎡"
      ></app-filter-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Số tầng</label>
      <app-filter-range
        [fromControl]="getControl('floorFrom')"
        [toControl]="getControl('floorTo')"
        title="Số tầng"
      ></app-filter-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Giá/m2</label>
      <app-filter-range
        title="Giá/m2"
        [rangePrice]="true"
        [unit]="unitPrice"
        [unitForm]="unitForm"
        [fromControl]="getControl('fromPrice')"
        [toControl]="getControl('toPrice')"
      ></app-filter-range>
    </div>

    <div class="col-5 mb-3">
      <label class="form-label">Tổng giá</label>
      <div class="d-flex align-items-center justify-content-between gap-3">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            (change)="onChangeCheckBox($event)"
            [class.disabled]="
              getControl('fromPrice')?.value || getControl('toPrice')?.value
            "
            [checked]="getControl('negotiate').value"
            formControlName="negotiate"
          />
          <label class="form-check-label">Thương lượng</label>
        </div>
        <div class="flex-1">
          <app-filter-range
            title="Tổng giá"
            [rangePrice]="true"
            [unit]="unitPrice"
            [unitForm]="unitForm"
            [fromControl]="getControl('fromPrice')"
            [toControl]="getControl('toPrice')"
            [disabled]="isChecked || !!getControl('negotiate').value"
          ></app-filter-range>
        </div>
      </div>
    </div>

    <div class="col mb-3">
      <label class="form-label">Loại tiền</label>
      <ng-select
        placeholder="Chọn loại tiền"
        [items]="CURRENCY"
        formControlName="currency"
        bindLabel="label"
        bindValue="key"
      ></ng-select>
    </div>
  </div>
</ng-container>
