import { Component, Input } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-form-owner-real-estate",
  templateUrl: "./form-owner-real-estate.component.html",
  styleUrls: ["./form-owner-real-estate.component.scss"],
})
export class FormOwnerRealEstateComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() realEstateId: string | number;
  @Input() isEdit: boolean;

  readonly PHONE_NUMBER_DEFAULT = 3;

  constructor(private fb: UntypedFormBuilder) {}

  get customers(): UntypedFormArray {
    return this.formRealEstate?.controls["customers"] as UntypedFormArray;
  }

  address(form: any) {
    return form.controls.address.controls;
  }

  addFieldPhone(i: any): void {
    const control = <UntypedFormArray>(
      (this.formRealEstate.controls["customers"] as UntypedFormArray).controls[
        i
      ].get("address")
    );

    if (control.length === this.PHONE_NUMBER_DEFAULT) return;

    control.push(
      this.fb.group({
        phone: ["", [Validators.required]],
      })
    );
  }

  removeFieldPhone(i: any, j: any): void {
    const control = <UntypedFormArray>(
      (this.formRealEstate.controls["customers"] as UntypedFormArray).controls[
        i
      ].get("address")
    );
    control.removeAt(j);
  }

  addOwner() {
    const owners = <UntypedFormArray>this.formRealEstate.controls["customers"];
    if (owners.length === this.PHONE_NUMBER_DEFAULT) return;
    const owner = this.fb.group({
      fullName: [],
      address: this.fb.array([
        this.fb.group({
          phone: ["", [Validators.required]],
        }),
      ]),
    });
    owners.push(owner);
  }

  removeOwner(index: number) {
    const owners = <UntypedFormArray>this.formRealEstate.controls["customers"];
    if (owners.length === 0) return;

    owners.removeAt(index);
  }
}
