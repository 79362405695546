import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  ACTIVE_STATUS_OPTIONS,
  BOOLEAN_OPTIONS,
} from "src/app/public/constants/common.constant";
import {
  CURRENCY,
  ORIGIN_PROVIDE,
  OWNER_GROUPS,
} from "src/app/public/constants/real-estate.constant";

@Component({
  selector: "app-form-detail-info",
  templateUrl: "./form-detail-info.component.html",
})
export class FormDetailInfoComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() realEstateId: string | number;
  @Input() isEdit: boolean;

  public CURRENCY = CURRENCY;
  public OWNER_GROUPS = OWNER_GROUPS;
  public ORIGIN_PROVIDE = ORIGIN_PROVIDE;
  public ACTIVE_STATUS_OPTIONS = ACTIVE_STATUS_OPTIONS;

  public isChecked: boolean = false;

  get controls() {
    return this.formRealEstate.controls;
  }

  public onChangeCheckBox(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.isChecked = inputElement.checked;

    this.formRealEstate.patchValue({
      negotiate: this.isChecked ? BOOLEAN_OPTIONS.TRUE : BOOLEAN_OPTIONS.FALSE,
    });
  }
}
