<form
  [formGroup]="sendEmailForm"
  (submit)="onSendEmailFormSubmit(sendEmailForm.value)"
  (keydown.enter)="$event.preventDefault()"
>
  <div class="modal-header p-3 bg-light">
    <h5 class="modal-title" id="composemodalTitle">Gửi Email</h5>
    <button
      type="button"
      class="btn-close"
      (click)="handleCloseModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div>
      <!--NGƯỜI GỬI  -->
      <div class="mb-3">
        <label>Từ:</label>
        <input
          type="text"
          class="form-control email-compose-input p-2"
          formControlName="sender"
          [class.is-invalid]="emails['sender'].errors"
        />
      </div>
      <!-- NGƯỜI NHẬN  -->
      <div class="mb-3 position-relative">
        <label>Đến:</label>
        <div
          class="choices mb-0"
          data-type="text"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div
            class="choices__inner form-control"
            (click)="recipientInput.focus()"
            [class.is-invalid]="
              emails['recipients'].errors && recipientControl.touched
            "
          >
            <div class="choices__list choices__list--multiple">
              <div
                class="choices__item choices__item--selectable"
                *ngFor="let control of recipients.controls; let index = index"
              >
                {{ control.value }}
                <button
                  type="button"
                  class="choices__button"
                  (click)="handleDeleteEmail(index, 'recipients')"
                >
                  Remove item
                </button>
              </div>
            </div>
            <input
              type="text"
              class="choices__input choices__input--cloned"
              [formControl]="recipientControl"
              (keydown.enter)="eventAddEmail(recipientControl, 'recipients')"
              (keydown.backspace)="
                eventDeleteEmail(recipientControl, 'recipients')
              "
              (input)="handleShowSuggestion(recipientControl)"
              #recipientInput
            />
          </div>
          <div
            class="choices__list choices__list--dropdown"
            [class.is-active]="isShowSuggestion"
          >
            <div
              class="choices__item choices__item--choice d-flex user-suggestion"
            >
              <div class="avatar-xs rounded-circle user-avatar me-3"></div>
              <div class="user-info">
                <h6 class="mb-0">Lê Mai Linh</h6>
                <p class="m-0">lemailinh22@gmail.com</p>
              </div>
            </div>
            <div
              class="choices__item choices__item--choice d-flex user-suggestion"
            >
              <div class="avatar-xs rounded-circle user-avatar me-3"></div>
              <div class="user-info">
                <h6 class="mb-0">Linh Lê</h6>
                <p class="m-0">19A02132@students.hou.edu.vn</p>
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute bottom-0 end-0">
          <div class="d-flex">
            <button
              class="btn btn-link text-reset fw-semibold px-2"
              type="button"
              (click)="this.isShowCc = !this.isShowCc"
            >
              Cc
            </button>
            <button
              class="btn btn-link text-reset fw-semibold px-2"
              type="button"
              (click)="this.isShowReplyTo = !this.isShowReplyTo"
            >
              Reply To
            </button>
          </div>
        </div>
      </div>

      <!-- CC  -->
      <div class="mb-3" *ngIf="isShowCc">
        <label>Cc:</label>
        <div
          class="choices"
          data-type="text"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div
            class="choices__inner form-control"
            (click)="ccInput.focus()"
            [class.is-invalid]="ccControl.errors && ccControl.touched"
          >
            <div class="choices__list choices__list--multiple">
              <div
                class="choices__item choices__item--selectable"
                *ngFor="let control of cc.controls; let index = index"
              >
                {{ control.value }}
                <button
                  type="button"
                  class="choices__button"
                  (click)="handleDeleteEmail(index, 'cc')"
                >
                  Remove item
                </button>
              </div>
            </div>
            <input
              type="text"
              class="choices__input choices__input--cloned"
              [formControl]="ccControl"
              (keydown.enter)="eventAddEmail(ccControl, 'cc')"
              (keydown.backspace)="eventDeleteEmail(ccControl, 'cc')"
              #ccInput
            />
          </div>
        </div>
      </div>

      <!-- REPLY TO  -->
      <div class="mb-3" *ngIf="isShowReplyTo">
        <label>Reply To:</label>
        <input
          type="text"
          class="form-control"
          formControlName="replyTo"
          [class.is-invalid]="
            emails['replyTo'].errors && emails['replyTo'].touched
          "
        />
      </div>
    </div>

    <!-- TIÊU ĐỀ  -->
    <div class="mb-3">
      <label>Tiêu đề:</label>
      <input
        type="text"
        class="form-control"
        formControlName="subject"
        [class.is-invalid]="
          emails['subject'].errors && emails['subject'].touched
        "
      />
    </div>

    <!-- BĐS -->
    <div class="mb-3">
      <textarea
        class="form-control"
        type="text"
        formControlName="productIds"
        rows="16"
        cols="114"
        (paste)="onPasteEvent($event)"
        (keydown.enter)="$event.stopPropagation()"
        [class.is-invalid]="
          (this.productIds.length > 6 || !(this.productIds.length > 0)) &&
          emails['productIds'].touched
        "
      ></textarea>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-ghost-danger"
      (click)="handleCloseModal()"
    >
      Huỷ bỏ
    </button>
    <button type="submit" class="btn btn-success">Gửi</button>
  </div>
</form>
