<div [formGroup]="formRealEstate" class="row">
  <div class="mb-n1">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i> Ngoại Thất</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="my-2 row">
    <!-- Base Example -->
    <div class="col-md-3 mt-4 fs-14">
      <div class="form-check form-check-inline">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="yard"
          class="form-check-input"
          type="checkbox"
          id="formCheck1"
        />
        <label class="form-check-label" for="formCheck1"> Sân </label>
      </div>

      <div class="form-check form-check-inline">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="garden"
          class="form-check-input"
          type="checkbox"
          id="formCheck2"
        />
        <label class="form-check-label" for="formCheck2"> Vườn </label>
      </div>

      <div class="form-check form-check-inline">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="balcony"
          class="form-check-input"
          type="checkbox"
          id="formCheckBc"
        />
        <label class="form-check-label" for="formCheckBc"> Ban công </label>
      </div>

      <div class="form-check form-check-inline">
        <input
          [attr.disabled]="isEdit ? null : true"
          formControlName="basement"
          class="form-check-input"
          type="checkbox"
          id="formCheckHam"
        />
        <label class="form-check-label" for="formCheckHam"> Tầng hầm </label>
      </div>
    </div>

    <!-- Base Example -->

    <div class="col-md-3 mt-2">
      <label class="form-label">Cửa sổ</label>
      <div class="">
        <input
          [attr.disabled]="isEdit ? null : true"
          type=""
          formControlName="windows"
          class="form-control"
          placeholder="(sl)"
        />
      </div>
    </div>

    <div class="col-md-3 mt-2">
      <label class="form-label">Hướng ban công</label>
      <ng-select
        [clearable]="false"
        [readonly]="!isEdit"
        formControlName="balconyDirection"
        [items]="balconyDirection$ | async"
        bindValue="cKey"
        bindLabel="cValue"
        placeholder="Hướng ban công..."
      ></ng-select>
    </div>
  </div>
</div>
