import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild, ViewEncapsulation
} from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";

@Component({
  selector: "app-file-image-uploads",
  templateUrl: "./file-image-uploads.component.html",
  styleUrls: ["./file-image-uploads.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

/**
 * FileUploads Component
 */
export class FileImageUploadsComponent implements AfterViewInit {
  files: any[] = [];
  linkFiles: String[] | any = [];
  CheckStyleUrl = CheckStyleUrl;

  @ViewChild("btnSubmitUpload") btnSubmitUpload: ElementRef;
  submit$ = new Subject();

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  @Input() isMultiFile: boolean = false;

  // Đường dẫn tới ảnh watermark
  private watermarkImg = 'assets/images/watermark.png'; // Đảm bảo đường dẫn đúng

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngAfterViewInit(): void {
    // Listen button submit and fire event `submit$`
    this.listenEventSubmitUpload();
    // Listen event `submit$` and upload images
  }

  listenEventSubmitUpload(): void {
    const submitUpload$ = fromEvent(
      this.btnSubmitUpload.nativeElement,
      "click"
    );
    submitUpload$.pipe().subscribe((_) => this.submit$.next(true));
  }

  onFileSelected(event: any): void {
    const files = event.target.files;
    if (files.length) {
      Array.from(files).forEach((file: any) => {
        if (file && file?.type === "image/jpeg") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result as string;
            img.onload = () => {
              this.addWatermark(img, file?.name, file?.type);
            };
          };
          reader.readAsDataURL(file);
        }
      });
    }
  }

  onDeleteFile(index: number): void {
    this.files.splice(index, 1);
    this.linkFiles.splice(index, 1);
  }

  approvedSubmit(): void {
    this.activeModal.close(this.files);
  }

  addWatermark(img: HTMLImageElement, name: string, mime: string) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const spacing = 60; // khoảng cách giữa các ảnh watermark.
    const reSize = 1/5; // kích thước so với ảnh gốc.
    const opacity = 0.8;

    // Thiết lập kích thước canvas bằng kích thước ảnh gốc
    canvas.width = img.width;
    canvas.height = img.height;

    // Vẽ ảnh gốc lên canvas
    ctx.drawImage(img, 0, 0);

    // Tải ảnh watermark
    const watermark = new Image();
    watermark.src = this.watermarkImg;
    watermark.onload = () => {
      const watermarkWidth = img.width * reSize; // Kích thước watermark
      const watermarkHeight = (watermarkWidth / watermark.width) * watermark.height; // Tính chiều cao theo tỉ lệ

      const numberLoop = img.width / watermarkWidth;
      ctx.globalAlpha = opacity;

      for (let i = 0; i < numberLoop; i++) {
        // Tính toán vị trí để vẽ watermark ở trung tâm
        const x = i > 0 ? (watermarkWidth * i) + (spacing * (1 + i)) : 0; // Vị trí x
        const y = (img.height - watermarkHeight) / 2; // Vị trí y

        // Vẽ watermark lên canvas
        ctx.drawImage(watermark, x, y, watermarkWidth, watermarkHeight);
      }
      // Thay thế ảnh gốc bằng ảnh có watermark
      const watermarkedImage = canvas.toDataURL('image/jpeg'); // Chất lượng JPEG từ 0.0 đến 1.0
      this.files.unshift(this.base64ToFile(watermarkedImage, name, mime));
    };
  }

  private base64ToFile(base64: string, fileName: string, mimeType: string): File {
    // Tách phần dữ liệu từ chuỗi base64
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // Chuyển đổi từng ký tự của chuỗi base64 thành byte
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Tạo Blob từ Uint8Array
    const blob = new Blob([ia], { type: mimeType });
    // Tạo File từ Blob
    return new File([blob], fileName, { type: mimeType });
  }
}
