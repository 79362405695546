import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { CURRENCY } from "src/app/public/constants/real-estate.constant";

@Component({
  selector: "app-filter-important-info",
  templateUrl: "./filter-important-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterImportantInfoComponent {
  @Input() formSearchAndFilter: FormGroup;
  @Input() unitPrice: string;
  @Input() unitForm: FormControl;

  public CURRENCY = CURRENCY;
  public isChecked: boolean = false;

  public getControl(name: string) {
    return this.formSearchAndFilter.controls[name] as UntypedFormControl;
  }

  public onChangeCheckBox(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.isChecked = inputElement.checked;
  }
}
