import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";

// Swiper Slider
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  throwError,
} from "rxjs";
import { catchError, take, tap } from "rxjs/operators";
import { ToastService } from "src/app/components/notifications/toast-service";
import { FileImageUploadsComponent } from "src/app/pages/real-estate-management/widgets/file-image-uploads/file-image-uploads.component";
import { BC_REAL_ESTATE_CREATION } from "src/app/public/constants/bread-crums";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";
import { PERMISSION_ACTIONS } from "src/app/public/constants/permission.contants";
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";
import { removeVietnameseTones } from "src/app/public/decorators/helper.decorator";
import {
  RealEstateModel,
  RealEstatePhotoModel,
} from "src/app/public/models/real-estate.model";
import { AddressRealEstateService } from "src/app/public/services/address/address-real-estate.service";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";
import { S3UploadService } from "src/app/public/services/aws/s3-upload.service";
import { RealEstateService } from "src/app/public/services/real-estate/real-estate.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-real-estate-creation",
  templateUrl: "./real-estate-creation.component.html",
  styleUrls: ["./real-estate-creation.component.scss"],
})
export class RealEstateCreationComponent {
  public BREAD_CRUMB_ITEMS = BC_REAL_ESTATE_CREATION;
  readonly PERMISSION_ACTIONS = PERMISSION_ACTIONS;

  public formRealEstate: FormGroup;
  public photosRealEstate: RealEstatePhotoModel[] = [];
  public statusRealEstate$: Observable<any>;
  public isCreateRealEstate$ = new BehaviorSubject<Boolean>(false);
  public photoUploads: Map<Number, File | Blob | any> = new Map();

  @ViewChild("inputFileEdit") inputFileEdit: ElementRef;
  @Output() createRealEstateEvent = new EventEmitter();
  readonly HANDLER_HOST = environment.handleHost;

  constructor(
    private fb: UntypedFormBuilder,
    private authorizationService: AuthorizationService,
    private modalService: NgbModal,
    public toastService: ToastService,
    private realEstateService: RealEstateService,
    private s3UploadService: S3UploadService,
    private addressRealEstateService: AddressRealEstateService,
    public dialogService: MatDialog
  ) {
    this.initForm();
    this.getPermissionCreate();
  }

  public getPermissionCreate() {
    const hasPermission = this.authorizationService.hasPermissionAction(
      "*",
      this.PERMISSION_ACTIONS.CREATE
    );
    this.isCreateRealEstate$.next(hasPermission);
  }

  public onCreateRealEstate() {
    const photoUpload$: any = [];
    const keys: any = [];
    this.photoUploads.forEach((value) => {
      if (value instanceof File || value instanceof Blob) {
        photoUpload$.push(this.s3UploadService.fileUpload(value));
      }
    });

    if (photoUpload$.length) {
      combineLatest(photoUpload$).subscribe((res) => {
        if (res) {
          const realEstate = this.getPayload();

          realEstate.photos.forEach((value, key) => {
            if (!value.id) keys.push(key);
          });

          const newPhotos = res.map((r: any) => {
            return {
              url: r.Location,
              changedAt: undefined,
              changedBy: "",
              createdAt: new Date(),
              createdBy:
                this.authorizationService.getCurrentUserLogin().username,
              height: 0,
              id: 0,
              latitude: null,
              longitude: null,
              pickedLocation: "",
              position: "",
              refId: "",
              size: 0,
              type: "",
              width: 0,
              status: "1",
            };
          });

          // set ảnh cho BĐS
          keys.forEach((key: number, index: number) => {
            if (!realEstate.photos) realEstate.photos = [];

            const file = realEstate.photos[key];
            //BĐS mới sẽ k có ID
            if (!file?.id) {
              realEstate.photos[key] = newPhotos[index];
            } else {
              realEstate.photos[key] = { ...file };
            }
          });

          // So sánh với list ảnh ban đầu nếu cái nào k có trong list mới thì sẽ set status = 0
          this.photosRealEstate &&
            this.photosRealEstate.forEach((photo) => {
              const hasImg = realEstate.photos.find((p) => p.id === photo.id);
              if (!hasImg) {
                realEstate.photos.push({
                  ...photo,
                  status: "0",
                });
              }
            });

          // set ảnh đầu tiên là mặc định
          const hasFrontImage = realEstate?.photos.find(
            (p) => (p.position = "01")
          );
          if (!hasFrontImage) {
            realEstate.photos = realEstate?.photos.map((photo, index) => {
              if (index === 0) photo.position = "01";
              return photo;
            });
          }
          this.handleCreateRealEstate(realEstate);
        }
      });
    } else if (this.formRealEstate.valid) {
      const realEstate = this.getPayload();
      this.photosRealEstate &&
        this.photosRealEstate.forEach((photo) => {
          const hasImg = realEstate.photos.find((p) => p.id === photo.id);
          if (!hasImg) {
            realEstate.photos.push({
              ...photo,
              status: "0",
            });
          }
        });

      this.handleCreateRealEstate(realEstate);
    } else {
      this.showDanger("Bạn phải điền hết các trường bắt buộc!");
    }
  }

  private getPayload(): RealEstateModel {
    const siteHandoverTime = this.f["siteHandoverTime"].value
      ? new Date(this.f["siteHandoverTime"].value).toISOString()
      : null;

    const contractTerm = this.f["contractTerm"].value
      ? new Date(this.f["contractTerm"].value).toISOString()
      : null;

    const result: RealEstateModel = {
      ...this.formRealEstate.getRawValue(),
      photos: this.photosRealEstate,
      siteHandoverTime,
      contractTerm,
    };

    const _city = this.addressRealEstateService.getNameOfCity(
      result.address.city
    );
    const _district = this.addressRealEstateService.getNameOfDistrict(
      result.address.district
    );
    const _town = this.addressRealEstateService.getNameOfTown(
      result.address.town
    );

    const fullAddress = [
      `${result.address?.houseNbr} ${result.address?.street}`,
      _town,
      _district,
      _city,
    ]
      .filter(Boolean)
      .join(", ");
    const shortAddress = [_town, _district, _city].filter(Boolean).join(", ");

    const fullAddressNoneSign = removeVietnameseTones(fullAddress);

    result.address.fullAddressNoneSign = fullAddressNoneSign;
    result.address.fullAddress = fullAddress;
    result.address.shortAddress = shortAddress;

    if (!result.photos) result.photos = [];

    return result;
  }

  private initForm(): void {
    this.formRealEstate = this.fb.group({
      purpose: [LAND_PURPOSE.FOR_RENT],
      type: ["3"],
      note: [""],
      status: [LAND_STATUS.NEW, Validators.required],
      photos: [],
      channel: ["AD"],
      floors: [0],
      price: [null],
      acreage: [0],
      frontWidth: [0, Validators.required],
      customers: this.fb.array([]),
      address: this.fb.group({
        changedAt: [""],
        changedBy: [""],
        city: [null, [Validators.required]],
        createdAt: [""],
        createdBy: [""],
        district: [null, [Validators.required]],
        houseNbr: [null, Validators.required],
        fullAddress: [""],
        shortAddress: [""],
        fullAddressNoneSign: [""],
        id: [""],
        latitude: [""],
        longitude: [""],
        streetView: [""],
        mobile: [""],
        phone: [""],
        refId: [""],
        street: [null, Validators.required],
        town: [null, [Validators.required]],
        type: [""],
      }),
      tenant: [null],
      negotiate: [0],
      currency: [null],
      businessStatus: [null],
      originProvide: [null, Validators.required],
      ownerGroup: [null],
      landArea: [""],
      floorArea: [""],
      description: [""],
      basement: [null],
      corner: [null],
      elevators: [null],
      signedBrokerageContract: [null],
      manager: [null, Validators.required],
      brokerage: [null, Validators.required],
      brokerageFee: [null],
      siteHandoverTime: [null],
      contractTerm: [null],
    });
  }

  private showSuccess(message: string): void {
    this.toastService.show(message, {
      classname: "bg-success text-center text-white",
      delay: 5000,
    });
  }

  private showDanger(message: string): void {
    this.toastService.show(message, {
      classname: "bg-danger text-center text-white",
      delay: 5000,
    });
  }

  get f() {
    return this.formRealEstate.controls;
  }

  public eventEditImage(index: number): void {
    fromEvent(this.inputFileEdit.nativeElement, "change")
      .pipe()
      .subscribe((event) => {
        // @ts-ignore
        const file = event?.target.files[0];
        file.oldName = this.getRootLinkImage(this.photosRealEstate[index].url);

        this.photoUploads.set(index, file);

        const reader = new FileReader();
        reader.onload = () => {
          this.photosRealEstate[index] = {
            ...this.photosRealEstate[index],
            url: reader.result as any,
            changedBy: "",
            status: "1",
          };
        };
        reader.readAsDataURL(file as any);
      });

    this.inputFileEdit.nativeElement.click();
  }

  public eventAddImage(): void {
    const modal = this.modalService.open(FileImageUploadsComponent, {
      centered: true,
      backdrop: "static",
      keyboard: true,
    });
    modal.closed.subscribe((data) => {
      const files = data;

      // kiểm tra xem mảng hiện tại có đang trống không.
      const hasImage: boolean = this.photoUploads.size > 0;
      // Sửa key-index của mảng hiện có vì vừa tăng thêm files?.length.
      if (hasImage) {
        let newMap = new Map();
        for (let [key, value] of this.photoUploads) {
          newMap.set(key + files.length, value);
        }
        this.photoUploads = newMap;
      }

      data.map((d: any, index: number) => {
        // set key = index để còn update vào mảng chứa ảnh sau khi upload lên S3.
        this.photoUploads.set(index, d);

        const reader = new FileReader();
        reader.onload = (e) => {
          if (!this.photosRealEstate) this.photosRealEstate = [];

          this.photosRealEstate.push({
            url: reader.result as any,
            changedAt: undefined,
            changedBy: "",
            createdAt: undefined,
            createdBy: "",
            height: 0,
            id: 0,
            latitude: null,
            longitude: null,
            pickedLocation: "",
            position: "",
            refId: "",
            size: 0,
            type: "",
            width: 0,
            status: "1",
          });
        };
        reader.readAsDataURL(d);
      });
    });
  }

  private handleCreateRealEstate(realEstate: RealEstateModel): void {
    this.realEstateService
      .addRealEstate(realEstate)
      .pipe(
        take(1),
        tap((_) => {
          this.showSuccess("Tạo mới thành công BĐS!");
          this.createRealEstateEvent.emit();
        }),
        catchError((error) => {
          this.showDanger("Lỗi không xác định!");
          return throwError(error);
        })
      )
      .subscribe(() => this.dialogService.closeAll());
  }

  public eventDeleteImage(index: number): void {
    this.photosRealEstate.splice(index, 1);
  }

  public eventSelectImgFront(index: number): void {
    const before = this.photosRealEstate[index];
    const after = this.photosRealEstate[0];

    this.photosRealEstate.splice(index, 1);
    this.photosRealEstate.splice(0, 1);

    this.photosRealEstate.unshift({ ...after, position: "" });
    this.photosRealEstate.unshift({ ...before, position: "01" });
  }
  public getRootLinkImage(image: string): string {
    if (image) {
      const imageName = image.replace(this.HANDLER_HOST, "");
      return imageName;
    }
    return image;
  }
}
