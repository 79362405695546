import { Pipe, PipeTransform } from "@angular/core";
import { IMAGE_FILE_TYPE } from "src/app/public/constants/common.constant";

@Pipe({
  name: "getMediumImage",
})
export class GetMediumImagePipe implements PipeTransform {
  SMALL_MEDIUM = "700x480";

  transform(url: string): string {
    const extension = url.split(/[#?]/)[0].split(".").pop().trim();
    const isImage = IMAGE_FILE_TYPE.includes(extension);
    if (isImage) {
      return url.replace("0x0", this.SMALL_MEDIUM);
    }
    return url;
  }
}
